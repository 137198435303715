<script setup>
import { clamp, range } from 'lodash-es'

let props = defineProps({
  id: String,
  name: String,
  role: String,
  description: String,
  email: String,
  videoPosition: {
    type: String,
    default: 'right',
    validator: value => ['left', 'right'].includes(value),
  },
})

function useAboutVideo({
  container,
  name,
  frames,
  startTitleOffset = -7.5,
  endTitleOffset = 10,
  startDescriptionOffset = -7,
  endDescriptionOffset = 3,
}) {
  let images = range(1, frames + 1).map(
    index => `/about-assets/images/${name}/jpg/frame-${index}.jpg`,
  )

  let progress = reactive({
    overall: 0,
    title: 0,
    description: 0,
  })

  let timeline = useGsapTimeline().add(
    useGsapFromTo(
      progress,
      computed(() => ({
        overall: 0,
        title: startTitleOffset,
        description: startDescriptionOffset,
      })),
      {
        overall: 1,
        title: endTitleOffset,
        description: endDescriptionOffset,
      },
    ),
    0,
  )
  let titleProgress = computed(() => clamp(progress.title, 0, 1))
  let descriptionProgress = computed(() => clamp(progress.description, 0, 1))

  useGsapScrollTrigger(container, {
    animation: timeline.instance,
    start: 'top 60%',
    end: 'bottom 40%',
    scrub: 1,
    log: true,
  })

  return {
    images,
    timeline,
    progress: computed(() => progress.overall),
    titleProgress,
    descriptionProgress,
  }
}

let optionsById = {
  ilja: {
    frames: 71,
    startTitleOffset: -5,
    startDescriptionOffset: -2,
  },
  reto: {
    frames: 102,
    startTitleOffset: -9.25,
    startDescriptionOffset: -3.5,
  },
  morris: {
    frames: 102,
    startTitleOffset: -5,
    startDescriptionOffset: -2,
  },
}

let containerRef = ref()
let about = useAboutVideo({
  container: containerRef,
  name: props.id,
  ...(optionsById[props.id] ?? {}),
})

let roleParts = computed(() => props.role.split('/').map(part => part.trim()))
</script>

<template>
  <div
    ref="containerRef"
    class="video-container"
    :class="[`video-container-${id}`, `video-position-${videoPosition}`]"
  >
    <div class="profile-container">
      <div class="profile" :style="{ '--profile-title-progress': about.titleProgress.value }">
        <h2 class="profile-headline">{{ name }}</h2>
        <h3 class="profile-subtitle">
          <template v-for="(part, index) in roleParts">
            <TextWithPendingSlash v-if="index === 0" class="profile-subtitle-slash" :text="part" />
            <template v-else>{{ ' ' }}{{ part }}</template>
          </template>
        </h3>
        <Scroll :progress="about.descriptionProgress.value">
          <div class="profile-description" v-html="description" />
          <p class="profile-contact">
            <strong class="profile-uplink">Uplink <Slash /></strong>
            <a class="profile-mailto-link" :href="`mailto:${email}`">
              <!-- prettier-ignore -->
              <svg class="profile-mailto-icon" aria-hidden="true" focusable="false" width="16" height="12" viewBox="0 0 16 12" xmlns="http://www.w3.org/2000/svg" data-v-2c42cb66=""><path d="M15 0H1a1 1 0 0 0-1 1v1.68l8 3.199 8-3.2V1A1 1 0 0 0 15 0ZM0 4.312V11a1 1 0 0 0 1 1h14a1 1 0 0 0 1-1V4.312l-8 3.2-8-3.2Z" fill="#FFF" fill-rule="evenodd" data-v-2c42cb66=""></path></svg>
              {{ email }}
            </a>
          </p>
        </Scroll>
      </div>
    </div>
    <div>
      <StripVideo
        class="video"
        :class="{ ready: about.progress.value > 0 && about.progress.value < 1 }"
        :images="about.images"
        :width="1920"
        :height="1080"
        :progress="about.progress.value"
        :webp="url => url.replaceAll('jpg', 'webp')"
      />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.video-container {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;

  height: var(--container-height, 500vh);

  & > * {
    grid-column: 1 / span 1;
    grid-row: 1 / span 1;
  }

  --profile-offset: 0px;
  --profile-max-width: 50ch;

  &.video-position-left {
    --video-margin-left: 0;

    @include mediaLG {
      --profile-offset: 15vw;
    }

    @include mediaXL {
      --profile-offset: 20vw;
    }
  }

  &.video-position-right {
    --video-margin-left: auto;

    @include mediaLG {
      --profile-offset: -15vw;
    }

    @include mediaXL {
      --profile-offset: -20vw;
    }
  }
}

.video {
  --video-width: min(100vw, 800px);
  --position-top: calc(var(--100lvh) - var(--video-height));
  --video-height: calc(var(--video-width) / (var(--video-aspect-ratio)));

  position: sticky;
  top: var(--position-top);
  display: block;
  width: var(--video-width);
  margin-left: auto;
  margin-right: auto;
  aspect-ratio: var(--video-aspect-ratio);
  object-fit: cover;
  opacity: 0;
  transition: var(--video-transition, opacity 1500ms);

  @include mediaLG {
    --video-width: 60vw;
    --position-top: calc((var(--100lvh) - var(--video-height)) / 2);
    margin-left: var(--video-margin-left, auto);
    margin-right: 0;
  }

  &.ready {
    opacity: 1;
  }
}

.profile-container {
  position: sticky;
  top: 100px;
  height: var(--100lvh);
  padding: 0 var(--container-padding);
  z-index: 1;
  display: flex;
  align-items: flex-start;
  justify-content: var(--profile-justify-content, flex-start);

  @include mediaLG {
    top: 0;
    align-items: center;
    padding: 0;
  }
}

.profile {
  --profile-vertical-offset: 0px;
  opacity: var(--profile-title-progress, 1);
  transform: translateX(calc(-40px * (1 - var(--profile-title-progress, 1))))
    translateX(var(--profile-offset)) translateY(var(--profile-vertical-offset));
  max-width: var(--profile-max-width);
  margin: 0 auto;

  .profile-headline {
    font-weight: bold;
    color: var(--primary-color);
    margin: 0;
  }

  .profile-subtitle {
    @include h2;
    margin: 0;
    margin-bottom: 0.5rem;
  }

  :deep(.profile-subtitle-slash) {
    display: inline-block;
    margin-bottom: -0.1em;
    margin-left: 0.2em;
    --slash-height: 0.9em;
    --slash-color: var(--primary-color);
  }

  .profile-description {
    filter: drop-shadow(0 1px 0.5em black);
  }

  .profile-contact {
    filter: drop-shadow(0 1px 0.5em black);
    display: flex;
    align-items: center;
    gap: 0.25em;
    margin-top: 2rem;
  }

  .profile-mailto-link {
    color: inherit;
    text-decoration: none;
    font-weight: bold;
  }

  .profile-mailto-icon {
    width: auto;
    height: 0.65em;
    margin-right: 0.25em;
    margin-left: 0.1em;
    margin-bottom: -0em;
  }

  .profile-uplink {
    display: flex;
    align-items: center;
    color: var(--primary-color);
    gap: 0.25em;
    --slash-color: currentColor;
  }
}
</style>
