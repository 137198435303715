<template>
  <component
    :is="tag"
    ref="container"
    class="skew-mask"
    :style="{
      '--skew-transition': allowTransition ? transition : 'none',
      ...skewPathCss,
    }"
  >
    <slot />
  </component>
</template>

<script setup>
let props = defineProps({
  tag: {
    default: 'div',
  },
  visibleFraction: {
    type: [String, Number],
    required: true,
    validator: value =>
      (typeof value === 'number' && value >= 0 && value <= 1) || ['min', 'max'].includes(value),
  },
  degrees: {
    type: Number,
    default: 19,
    validator: value => Number.isFinite(value) && value > 0,
  },
  visibleSide: {
    type: String,
    default: 'left',
    validator: value => ['left', 'right'].includes(value),
  },
  transition: {
    type: String,
    default: 'none',
  },
})

let { degrees, visibleFraction, visibleSide } = toRefs(props)

let container = ref()
let { css: skewPathCss } = reactive(
  useSkewPath(container, visibleFraction, {
    degrees,
    visibleSide,
  }),
)

let allowTransition = ref(false)

watch(
  () => props.visibleSide,
  () => {
    allowTransition.value = false

    nextTick(() => {
      setTimeout(() => {
        allowTransition.value = true
      }, 0)
    })
  },
)

onUnmounted(() => {
  allowTransition.value = false
})

onMounted(() => {
  let { stop } = useResizeObserver(container, () => {
    stop()

    setTimeout(() => {
      allowTransition.value = true
    }, 0)
  })
})
</script>

<style lang="scss" scoped>
.skew-mask {
  transition: clip-path var(--skew-transition);
  clip-path: var(--skew-clip-path);
}
</style>
