import { h } from 'vue'

export default {
  props: {
    tree: Object,
    elements: Object,
  },
  setup(props) {
    let renderNode = node => {
      if (node.visible === false) return null

      if (!(node.id in props.elements)) {
        return h(
          'pre',
          { style: 'color: red' },
          `Unknown element "${node.id}":\n${JSON.stringify(node, null, 2)}`,
        )
      }

      let element = props.elements[node.id]

      let nodeProps = {}
      if (node.id !== 'root') {
        Object.assign(nodeProps, node.properties)

        if ('children' in element.props) {
          nodeProps.children = node.children
        }
      }

      return h(element, nodeProps, () => node.children.map(renderNode))
    }

    return () => renderNode(props.tree)
  },
}
