<template>
  <div
    class="ui-input"
    :class="[
      {
        disabled,
        invalid: isInvalid,
        dirty,
      },
      $attrs.class,
    ]"
    @focusin="hasFocus = true"
    @focusout="hasFocus = false"
    :style="$attrs.style"
  >
    <label class="label" :for="id">
      <slot>
        {{ label }}
        <!-- <span v-if="required" class="required-asterisk" aria-hidden="true">*</span> -->
      </slot>
    </label>
    <input
      v-if="!multiline"
      class="input"
      ref="input"
      v-bind="inputProps"
      @input="onInput"
      @change="onChange"
      @blur="onBlur"
      @invalid="onInvalid"
      @animationstart="onAnimationstart"
    />
    <textarea
      v-else
      class="input"
      rows="1"
      ref="input"
      v-bind="inputProps"
      @input="onInput"
      @change="onChange"
      @blur="onBlur"
      @invalid="onInvalid"
      @animationstart="onAnimationstart"
    />
    <SlideVertical>
      <div class="error" v-if="(form.validated || visited) && errorMessage" role="alert">
        {{ errorMessage }}
      </div>
    </SlideVertical>
  </div>
</template>

<script>
import { omit } from 'lodash-es'
import { uuid } from '@/assets/js/util'

export default {
  inheritAttrs: false,
  props: {
    label: String,
    type: {
      type: String,
      default: 'text',
    },
    modelValue: {
      type: [Object, Array, String, Number],
    },
    invalid: null,
    multiline: Boolean,
    required: Boolean,
    disabled: Boolean,
    pattern: [String, RegExp],
  },
  emits: ['update:modelValue', 'update:focused', 'update:visited', 'update:valid'],
  inject: ['form'],
  data: () => ({
    dirty: false,
    visited: false,
    hasFocus: false,
    nativeInvalidityMessage: '',
    randomId: uuid(),
  }),
  computed: {
    id: vm => vm.$attrs.id ?? vm.randomId,
    inputProps: vm => ({
      ...omit(vm.$attrs, ['class', 'style']),
      type: vm.type,
      disabled: vm.disabled,
      required: vm.required,
      pattern: vm.patternString,
      value: vm.modelValue,
      id: vm.id,
    }),
    isInvalid: vm => vm.invalid || vm.nativeInvalidityMessage,
    isPasswordField: vm => vm.type === 'password',
    errorMessage: vm =>
      (typeof vm.invalid === 'string' ? vm.invalid : '') || vm.nativeInvalidityMessage,
    patternString: vm => (vm.pattern instanceof RegExp ? vm.pattern.source : vm.pattern),
  },
  watch: {
    invalid() {
      this.setCustomValidity()
    },

    // Sync 'focus' state with parent
    hasFocus: {
      handler(value) {
        this.$emit('update:focused', value)
      },
      immediate: true,
    },
  },
  mounted() {
    this.setCustomValidity()

    if (this.multiline) {
      this.adjustHeight()
    }
  },
  methods: {
    setCustomValidity() {
      if (typeof this.invalid === 'string') {
        this.$refs.input.setCustomValidity(this.invalid)
      } else if (this.invalid) {
        this.$refs.input.setCustomValidity('Ungültige Eingabe')
      } else {
        this.$refs.input.setCustomValidity('')
      }
    },
    onInput(event) {
      this.$emit('update:modelValue', event.target.value)

      if (this.multiline) {
        this.adjustHeight()
      }
    },
    adjustHeight() {
      this.$refs.input.style.removeProperty('height')

      if (this.$refs.input.clientHeight < this.$refs.input.scrollHeight) {
        let computed = getComputedStyle(this.$refs.input)
        this.$refs.input.style.height = `${
          this.$refs.input.scrollHeight -
          parseFloat(computed.paddingTop) -
          parseFloat(computed.paddingBottom)
        }px`
      }
    },
    onBlur(event) {
      this.visited = true

      // Sync 'visisted' state with parent
      this.$emit('update:visited', true)

      this.onInvalid(event)
    },
    onInvalid(event) {
      let input = event.target
      if (input.validity.customError) {
        // If custom validity exists, remove it temporarily to get native message
        let customErrorMessage = input.validationMessage
        input.setCustomValidity('')
        this.nativeInvalidityMessage = input.validationMessage
        input.setCustomValidity(customErrorMessage)
      } else {
        this.nativeInvalidityMessage = input.validationMessage
      }
    },
    onChange(event) {
      this.dirty = true
    },

    // Sync 'valid' state with parent
    // Detecting validity this way is quite a hack, but it seems to be the only
    // dependable way. Only watching the 'invalid' event + value changes is not
    // sufficient as environmental conditions might change (e.g. conditional 'required')
    onAnimationstart(event) {
      if (event.animationName === 'detect-valid') {
        if (!this.hasFocus) {
          this.nativeInvalidityMessage = ''
        }

        this.$emit('update:valid', true)
        this.$el.dispatchEvent(
          new CustomEvent('input:validate', { detail: { valid: true }, bubbles: true }),
        )
      } else if (event.animationName === 'detect-invalid') {
        this.$emit('update:valid', false)
        this.$el.dispatchEvent(
          new CustomEvent('input:validate', { detail: { valid: false }, bubbles: true }),
        )
      }
    },
  },
}
</script>

<style lang="scss">
.ui-input {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: stretch;
  border-bottom: 1px solid var(--input-border-color, currentColor);

  @include mediaSM {
    align-items: initial;
    flex-direction: row;
    column-gap: 0.75em;
  }

  &:focus-within {
    box-shadow: 0 1px 0 var(--input-border-color, currentColor);
  }

  .label {
    white-space: nowrap;

    @include mediaSM {
      cursor: text;
    }
  }

  .input {
    --scroll-margin-offset: 20px;

    border: none;
    background-color: transparent;
    color: var(--input-text-color, inherit);
    border-radius: 0;
    font: inherit;
    flex-grow: 1;
    transition: all 150ms;
    padding: 0.85em 0;
    min-width: 0;

    @include mediaSM {
      width: 0;
    }

    &::placeholder {
      color: var(--input-placeholder-color, inherit);
      opacity: 0.5;
    }

    // Hide Edge's password reveal
    &::-ms-reveal,
    &::-ms-clear {
      display: none;
    }

    &:disabled {
      opacity: 0.6;
    }

    /* &:not(:disabled):hover {
      border-color: $blue-500;
    } */

    &:focus {
      outline: none;
    }
  }

  .label,
  .input {
    // Increase normalized line-height to avoid clipping input text
    line-height: 1.3;
    font-weight: bold;
  }

  .label {
    margin-top: 1rem;

    @include mediaSM {
      margin-top: 0;
      padding: 0.85em 0;
    }
  }

  // TODO: Make textarea auto-grow
  textarea.input {
    resize: none;
  }

  .error {
    flex-basis: 100%;
    margin: -0.5rem 0 0.5rem;
    padding: 0 0;
    font-weight: bold;
    display: block;
    line-height: 1;
    font-size: 0.65em;
    color: var(--form-error);
  }
}

.required-asterisk {
  color: var(--form-error);
}

@keyframes detect-valid {
}
@keyframes detect-invalid {
}

.input:valid {
  animation-name: detect-valid;
}

.input:invalid {
  animation-name: detect-invalid;
}
</style>
