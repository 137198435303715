<template>
  <footer class="contact-footer">
    <SkewMask class="contact-footer-mask" visible-fraction="max" visible-side="right">
      <div class="footer-layout">
        <ContactData />
      </div>
    </SkewMask>
  </footer>
</template>

<script setup></script>

<style lang="scss" scoped>
.contact-footer {
  position: fixed;
  bottom: 0;
  right: 0;
  width: var(--sidebar-width, 50vw);
  height: 100%;
  color: #fff;

  animation: fade-in 300ms;
}

.contact-footer-mask {
  --columns: 6fr auto 1fr;
  width: 100%;
  height: 100%;
  background-color: #000;
  display: grid;
  align-items: flex-end;
  grid-template-columns: var(--columns);

  @media (min-height: 800px) {
    --columns: 2.5fr auto 1fr;
  }

  ::selection {
    background-color: var(--primary-color);
    color: #000;
  }

  & > * {
    grid-column: 2;
  }
}

.footer-layout {
  margin-bottom: space(1);
  padding-right: 20px;
}
</style>
