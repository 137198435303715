<script setup>
defineProps({
  source: Object,
})

let parent = useAsChild({ key: 'wpce-swiper' })

let mediaRef = ref()
let mediaControls = computed(() => mediaRef.value?.mediaControls)
let pausedManually = computed(() => mediaRef.value?.pausedManually)
let autoplay = computed(() => mediaRef.value?.autoplay)

defineExpose({
  mediaControls,
  pausedManually,
  autoplay,
})
</script>

<template>
  <div>
    <WpceVideo
      class="media-swiper-video"
      ref="mediaRef"
      v-if="source.id === 'video'"
      v-bind="source.properties"
    />
    <WpceImage class="media-swiper-image" ref="mediaRef" v-else v-bind="source.properties" />
  </div>
</template>

<style lang="scss" scoped></style>
