/**
 * Recognize whether the user is currently dragging the pointer
 * Can be useful to differentiate between a click/tap and a drag/swipe
 */
export function useDragRecognition({ distance = 10 } = {}) {
  distance = ref(distance)
  let dragging = ref(false)
  let down = ref()

  let stopCancelListen, stopEndListen, stopMoveListen, stopContextmenuListen

  useEventListener(
    'pointerdown',
    event => {
      if (dragging.value) {
        stopCancelListen()
        stopEndListen()
        stopContextmenuListen()
        stopMoveListen()
        dragging.value = false
        down.value = undefined
        return
      }

      if (down.value && event.pointerId !== down.value) return

      down.value = event.pointerId

      let startX = event.x
      let startY = event.y

      let stopHandler = event => {
        stopCancelListen()
        stopEndListen()
        stopContextmenuListen()
        stopMoveListen()
        down.value = undefined

        setTimeout(() => {
          dragging.value = false
        }, 0)
      }

      stopContextmenuListen = useEventListener('contextmenu', stopHandler, {
        passive: true,
      })
      stopCancelListen = useEventListener('pointercancel', stopHandler, {
        passive: true,
      })
      stopEndListen = useEventListener('pointerup', stopHandler, {
        passive: true,
      })

      stopMoveListen = useEventListener(
        'pointermove',
        event => {
          let currentDistance = Math.sqrt(
            Math.pow(startX - event.x, 2) + Math.pow(startY - event.y, 2),
          )

          if (currentDistance > distance.value) {
            stopMoveListen()
            dragging.value = true
          }
        },
        { passive: false },
      )
    },
    { passive: true },
  )

  return readonly(dragging)
}
