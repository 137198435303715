<script setup>
let props = defineProps({
  video: Array,
  headline: String,
  headlineFlags: Object,
  alignment: String,
  poster: Array,
  autoplay: {
    type: [Boolean, String],
    default: false,
    validator: v => ['intersect', true, false].includes(v),
  },
  muted: Boolean,
  showControls: {
    type: [Boolean, String],
    default: 'hover',
    validator: v => ['interaction', 'hover', 'always', false].includes(v),
  },
})

let hasHeadline = computed(() => (props.headline ?? '').trim().length > 0)

let { data: videoUrl, pending } = await useWordpressData(
  `wp-remote/attachment/url/${props.video[0].id}`,
)

let poster =
  props.poster?.length > 0
    ? await useWordpressData(`wp-remote/image/url/${props.poster[0].id}`)
    : undefined
</script>

<template>
  <FullscreenVideoContainer
    v-if="!pending"
    :video="videoUrl"
    :poster="poster"
    :show-controls="showControls"
    :autoplay="autoplay"
  >
    <div v-if="hasHeadline" class="container-wide wpce-hero-video">
      <HeroHeadline
        v-if="hasHeadline"
        class="wpce-hero-video-headline"
        :class="alignment"
        :highlight-first-line="headlineFlags?.firstLine ?? false"
        :highlight-last-line="headlineFlags?.lastLine ?? false"
        with-slash
        v-enter
        :text="headline"
      />
    </div>
  </FullscreenVideoContainer>
</template>

<style lang="scss" scoped>
.wpce-hero-video {
  --height: min(50%, 50vh);
  --margin: min(25%, 25vh);

  @include mediaSM {
    --height: min(80%, 80vh);
    --margin: min(10%, 10vh);
  }

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  align-self: flex-start;
  height: var(--height);
  margin-top: var(--margin);
  margin-bottom: var(--margin);
}

.wpce-hero-video-headline {
  margin-top: auto;

  &.align-top {
    margin-top: 0;
    margin-bottom: auto;
  }

  &.align-middle {
    margin-top: auto;
    margin-bottom: auto;
  }

  &.align-bottom {
    margin-top: auto;
    margin-bottom: 0;
  }
}

.wpce-hero-video-content {
  --padding-x: 2.5rem;
  --padding-y: 3rem;
  background-color: rgb(0 0 0 / 75%);
  color: #fff;

  @include mediaSM {
    --padding-x: 6rem;
    --padding-y: 3rem;
  }

  &.align-middle {
    margin-top: auto;
    margin-bottom: auto;
  }

  &.align-bottom {
    margin-top: auto;
  }
}
</style>
