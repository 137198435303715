<template>
  <SkewTransition :visible="visible" out="rtl" class="menu" transition="650ms">
    <nav @keydown.escape.prevent="emit('update:visible', false)" class="menu-nav" tabindex="-1">
      <ul v-if="!pending && items" class="menu-list">
        <li v-for="{ title, path, slug } in items" :key="slug" class="menu-item" v-enter="'menu'">
          <NuxtLink :to="path" @click="emit('update:visible', false)">{{ title }}</NuxtLink>
        </li>
      </ul>
    </nav>
  </SkewTransition>
</template>

<script setup>
defineProps({
  visible: Boolean,
})

let emit = defineEmits(['update:visible'])

let { data: items, pending } = await useWordpressData('wp-remote/menu')
</script>

<style lang="scss" scoped>
.menu {
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 490;
  overflow-y: auto;
  overscroll-behavior: contain;

  --menu-top-padding: 5rem;
  --menu-bottom-padding: 10rem;
  --menu-left-padding: 5rem;
  --menu-right-padding: 3rem;

  --menu-item-line-height: 1.4;
  --menu-items: 6;

  --menu-items-available-space: calc(
    100vmin - var(--menu-top-padding) - var(--menu-bottom-padding)
  );
  --menu-items-available-space-per-item: calc(
    var(--menu-items-available-space) / var(--menu-items)
  );
  --menu-items-available-font-size: calc(
    var(--menu-items-available-space-per-item) / var(--menu-item-line-height)
  );

  @include mediaSM {
    --menu-left-padding: 100px;
    --menu-right-padding: 200px;
  }

  .menu-list {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  .menu-nav {
    box-sizing: border-box;
    height: 100%;
    padding: var(--menu-top-padding) var(--menu-right-padding) var(--menu-bottom-padding)
      var(--menu-left-padding);
    background-color: #000;

    @include focus {
      outline: none;
    }
  }

  .menu-item {
    line-height: var(--menu-item-line-height);
    font-weight: bold;
    font-size: clamp(36px, var(--menu-items-available-font-size), 100px);
    padding: 0;

    a {
      transition: padding-right 300ms;
      padding-right: 0.5em;

      &::before {
        content: '';

        display: inline-block;
        max-width: 0;
        width: 100vw;
        height: 0.75em;
        position: relative;
        bottom: -0.03em;
        margin-right: 0;
        transform: skew(calc(-1 * var(--skew-angle)));
        transition: max-width 300ms, margin-right 300ms;
        text-decoration: none;

        background-color: var(--primary-color);
        line-height: 1;
      }

      &:not([aria-current='page']):hover {
        padding-right: 0;

        &::before {
          max-width: 0.15em;
          margin-right: 0.35em;
        }
      }

      &[aria-current='page'] {
        padding-right: 0;
        color: var(--primary-color);

        &::before {
          background-color: #fff;
          max-width: 0.15em;
          margin-right: 0.35em;
        }
      }

      @include focus {
        outline: none;
        text-decoration: underline;
        text-decoration-thickness: 0.125em;
        text-underline-offset: 0.075em;
        text-decoration-color: var(--primary-color);
      }
    }
  }

  .menu-item {
    text-transform: uppercase;
  }

  a {
    text-decoration: none;
    color: #fff;
    outline: none;
  }

  a:hover,
  a:focus {
    color: var(--color-link-hover);
    outline: none;
  }
}
</style>
