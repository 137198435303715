<script setup>
let props = defineProps({
  element: Object,
})

let { data: tree, pending } = await useWordpressData(`wp-remote/global-element/${props.element.id}`)
</script>

<template>
  <WpceDocument v-if="!pending && tree" :tree="tree" />
</template>
