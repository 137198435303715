<template>
  <div class="mp-swiper-wrapper">
    <Swiper
      @swiper="onSwiper"
      ref="swiperRef"
      class="mp-swiper"
      :slides-per-view="1"
      :space-between="0"
      v-bind="{ ...defaultOptions, ...$attrs }"
    >
      <slot />
    </Swiper>

    <div class="swiper-navigation">
      <button class="swiper-nav-button swiper-prev" @click.stop="onClickPrev">
        <!-- prettier-ignore -->
        <svg class="swiper-nav-icon" aria-hidden="true" focusable="false" width="12" height="22" viewBox="0 0 12 22" xmlns="http://www.w3.org/2000/svg"><polyline points="7.72193381 0 11.9688514 0 4.47079818 11.2632979 12 22 7.76184296 22 -1.24344979e-14 11.2828014 7.72193381 0" fill="currentColor" fill-rule="evenodd"/></svg>
        <span class="sr-only">Previous</span>
      </button>
      <button class="swiper-nav-button swiper-next" @click.stop="onClickNext">
        <!-- prettier-ignore -->
        <svg aria-hidden="true" focusable="false" width="12" height="22" viewBox="0 0 12 22" xmlns="http://www.w3.org/2000/svg"><polyline points="4.27806619 0 0.0311486048 0 7.52920182 11.2632979 -1.24344979e-14 22 4.23815704 22 12 11.2828014 4.27806619 0" fill="currentColor" fill-rule="evenodd"/></svg>
        <span class="sr-only">Next</span>
      </button>
    </div>
  </div>
</template>

<script>
export default {
  inheritAttrs: false,
}
</script>

<script setup>
import { Swiper, SwiperSlide } from 'swiper/vue'
import 'swiper/css'

let swiper = ref()
function onSwiper(swiperInstance) {
  swiper.value = markRaw(swiperInstance)
}

let defaultOptions = {
  loop: true,
  threshold: 15,
}

let swiperRef = ref()

function onClickPrev() {
  swiper.value.slidePrev()
}

function onClickNext() {
  swiper.value.slideNext()
}

defineExpose({
  swiper,
})
</script>

<style lang="scss" scoped>
.mp-swiper-wrapper {
  --icon-width: 12px;
  --icon-height: 22px;
  --icon-inner-padding: 15px;
  // --icon-outer-padding: -10px;
  --icon-outer-padding: 20px;
  --button-offset: 0px;

  @include mediaSM {
    --button-offset: 10px;
    --icon-inner-padding: 20px;
  }

  @include mediaMD {
    --icon-outer-padding: 10px;
  }

  @include mediaLG {
    --button-offset: 25px;
    --icon-outer-padding: 20px;
  }

  margin: space(1) auto;
  position: relative;
  max-width: calc(
    100vw - 2 *
      (
        var(--icon-width) + var(--icon-inner-padding) + var(--icon-outer-padding) +
          var(--button-offset)
      )
  );
  // margin-left: calc(
  //   -1 * (var(--icon-width) + var(--icon-inner-padding) + var(--icon-outer-padding) +
  //         var(--button-offset))
  // );

  // @include mediaSM {
  //   max-width: calc(
  //     100% - (var(--icon-width) + var(--icon-inner-padding) + var(--icon-outer-padding))
  //   );
  //   margin-left: calc(
  //     0.5 * (var(--icon-width) + var(--icon-inner-padding) + var(--icon-outer-padding))
  //   );
  // }

  @include mediaLG {
    // margin-left: 0;
    // max-width: none;
  }
}

.mp-swiper {
  height: 100%;
}

.swiper-navigation {
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  background-color: red;
  z-index: 1;
}

.swiper-nav-button {
  @include reset-form-ui;

  box-sizing: content-box;
  width: var(--icon-width);
  height: var(--icon-height);
  position: absolute;
  top: calc(-0.5 * var(--icon-height) - 20px);
  padding: 20px;

  color: var(--primary-color);
  cursor: pointer;
}

.swiper-prev {
  right: 100%;
  margin-right: var(--button-offset);
  padding-left: var(--icon-outer-padding);
  padding-right: var(--icon-inner-padding);
}

.swiper-next {
  left: 100%;
  margin-left: var(--button-offset);
  padding-left: var(--icon-inner-padding);
  padding-right: var(--icon-outer-padding);
}
</style>
