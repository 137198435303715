import { ensureArray } from '~~/assets/js/util'

const PENDING = 0
const LOADED = 1
const ERROR = 2

export function usePreload(rawResources) {
  rawResources = ref(rawResources)
  let resources = computed(() => ensureArray(unref(rawResources)))

  let preloadElements = []
  let loadingStates = ref([])
  watch(
    resources,
    resourcesValue => {
      if (!Array.isArray(resourcesValue)) {
        loadingStates.value = []
        return
      }

      loadingStates.value = resourcesValue.map(() => PENDING)
    },
    { immediate: true },
  )

  let augmentedResources = computed(() => {
    if (!Array.isArray(resources.value)) return []

    return resources.value.map(resource => {
      resource = typeof resource === 'string' ? { url: resource } : { ...resource }

      if (!resource.as) {
        // Pick preloadLink 'as' attribute based on 'resources' URL file extension
        resource.as = (() => {
          switch (resource.url.split('?')[0].split('.').pop().toLowerCase()) {
            case 'css':
              return 'style'
            case 'mjs':
            case 'js':
              return 'script'
            case 'html':
              return 'document'
            case 'woff':
            case 'woff2':
            case 'ttf':
            case 'eot':
            case 'otf':
              return 'font'
            case 'mp3':
            case 'ogg':
            case 'wav':
              return 'audio'
            case 'jpg':
            case 'jpeg':
            case 'png':
            case 'gif':
            case 'webp':
            case 'avif':
            case 'svg':
              return 'image'
            case 'mp4':
            case 'webm':
            case 'ogv':
            case 'mov':
              return 'video'
            default:
              return 'fetch'
          }
        })()
      }

      if (typeof resource.crossOrigin === 'undefined') {
        resource.crossOrigin = resource.as === 'fetch'

        // We preload data from the WordPress API, therefore we need to set the crossOrigin attribute to be set
        // resource.crossOrigin = true
      }

      return resource
    })
  })

  if (import.meta.env.SSR) {
    let preloadLinks = augmentedResources.value.map(resource => ({
      rel: 'preload',
      href: resource.url,
      as: resource.as,
      crossOrigin:
        typeof resource.crossOrigin === 'boolean'
          ? resource.crossOrigin
            ? 'anonymous'
            : undefined
          : resource.crossOrigin,
    }))

    useHead({
      link: preloadLinks,
    })
  } else {
    for (let index = 0; index < augmentedResources.value.length; index++) {
      let resource = augmentedResources.value[index]

      // Create preload link element based on 'resources' URL
      let preloadLink = document.createElement('link')
      preloadLink.rel = 'preload'
      preloadLink.href = resource.url
      preloadLink.as = resource.as
      preloadLink.crossOrigin =
        typeof resource.crossOrigin === 'boolean'
          ? resource.crossOrigin
            ? 'anonymous'
            : undefined
          : resource.crossOrigin

      useEventListener(preloadLink, 'load', () => {
        loadingStates.value.splice(index, 1, LOADED)
      })

      useEventListener(preloadLink, 'error', () => {
        loadingStates.value.splice(index, 1, ERROR)
      })

      preloadElements.push(preloadLink)
    }

    useHeadElement(preloadElements)
  }

  let errors = computed(() => {
    let errorUrls = []
    for (let index = 0; index < loadingStates.value.length; index++) {
      if (loadingState === ERROR) {
        errorUrls.push(augmentedResources.value[index].url)
      }
    }
    return errorUrls
  })

  let completed = computed(() => {
    return loadingStates.value.filter(loadingState => loadingState !== PENDING)
  })
  let completeCounter = computed(() => completed.value.length)
  let isComplete = computed(() => completeCounter.value === augmentedResources.value.length)

  return { isComplete, completeCounter, errors }
}
