<template>
  <div class="page">
    <MenuButton v-model:menu-visible="menu.active" @keydown.escape.prevent="menu.active = false" />
    <Menu v-model:visible="menu.active" />

    <main :inert="menu.active ? '' : null">
      <slot />
    </main>

    <Footer :inert="menu.active ? '' : null" />
  </div>
</template>

<script setup>
let menu = useMenuStore()
</script>

<style lang="scss"></style>
