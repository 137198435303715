export function useWindowResizeStartStop(startCallback, stopCallback, { debounce = 500 } = {}) {
  let started = ref(false)

  let timeoutController
  let stop = useEventListener(
    'resize',
    () => {
      if (!started.value) {
        startCallback()
      }

      started.value = true

      timeoutController?.stop()
      timeoutController = useTimeoutFn(() => {
        started.value = false
        stopCallback()
      }, debounce)
    },
    { passive: true },
  )

  return () => {
    timeoutController?.stop()
    stop()
  }
}
