<template>
  <div class="slash-container">
    <div class="slash" />
  </div>
</template>

<style lang="scss">
.slash-container {
  --slash-height: var(--slash-size, 1em);
  --slash-width: calc(var(--slash-height) / 6);

  display: var(--slash-display, block);
  height: var(--slash-height);
  width: calc(var(--slash-width) + var(--triangle-height-fraction) * var(--slash-height));

  .slash {
    height: 100%;
    width: var(--slash-width);
    margin: auto;
    transform: skew(calc(-1 * var(--skew-angle)));
    background-color: var(--slash-color, #fff);
  }
}
</style>
