import { encodeBase64, futureTick, revHash } from '~~/assets/js/util'

export type WordPressDataOptions<T> = {
  body?: any
  extract?: (data: any) => T
}

export default function useWordpressData<T = any>(
  endpoint: string,
  {
    // Body data (makes the request a POST request)
    body,
  }: WordPressDataOptions<T> = {},
) {
  let config = useRuntimeConfig()

  // Assemble the WordPress API base URL
  let wpApiBase = config.public.wordpress?.base
  if (wpApiBase.startsWith('/')) {
    wpApiBase = new URL(wpApiBase, window.location.origin).toString()
  }
  wpApiBase = wpApiBase.replace(/\/$/, '').concat('/wp-json/')

  // Assemble request headers
  let headers: Record<string, string> = {}

  // Add basic auth headers if configured
  if (config?.wordpress?.basicAuth?.user) {
    headers.Authorization = `Basic ${encodeBase64(
      `${config.wordpress.basicAuth.user}:${config.wordpress.basicAuth.password}`,
    )}`
  }

  let progressStore = useProgressStore()
  let progress = ref()
  progressStore.track(useProgress(progress, 'wordpress endpoint ' + endpoint))

  let bodySuffix = ''
  if (typeof body !== 'undefined') {
    bodySuffix = `.body-${revHash(JSON.stringify(body))}`
  }

  return useAsyncData(
    `wp-content:${endpoint}${bodySuffix}`,
    () => {
      // Data fetching directly through the browser in dynamic mode
      if (config.public.wordpress?.dynamic) {
        let options: any = {
          headers,
          credentials: 'include',
        }

        if (typeof body !== 'undefined') {
          Object.assign(options, {
            method: 'POST',
            body,
          })
        }

        return $fetch(new URL(endpoint, wpApiBase).toString(), options).then(result => {
          futureTick(10).then(() => {
            progress.value = true
          })

          return result
        })
      }

      // Data fetching through API middleware
      let [url, query] = endpoint.split('?')

      let querySuffix = ''
      if (typeof query === 'string') {
        let hash = revHash(query)
        querySuffix = `.query-${hash}`
      }

      // Only use POST requests where the API proxy is used
      // (which is either on localhost during development or during
      // the SSR side of static generation)
      let options
      if (import.meta.env.SSR || process.env.NODE_ENV === 'development') {
        options = {
          method: 'POST',
          body: {
            data: body,
            query: query,
          },
        }
      }

      return $fetch(`/api/${url}${bodySuffix}${querySuffix}.json`, options).then(result => {
        // Wait 10 ticks before resolving the progress
        // This allows for components dependent on the current request to be mounted
        // and queue their own progress tracking
        futureTick(10).then(() => {
          progress.value = true
        })

        return result
      })
    },
    {
      server: !config.public.wordpress.dynamic,
      lazy: true,
    },
  )
}
