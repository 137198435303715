export function useSkewPath(element, visibleFraction, { degrees = 16, visibleSide = 'left' } = {}) {
  visibleFraction = ref(visibleFraction)
  degrees = ref(degrees)
  visibleSide = ref(visibleSide)

  let { height: windowHeight } = useWindowSize()

  let { height } = useElementSize(element)
  let cachedHeight = ref(height.value || windowHeight.value)
  watch(height, heightValue => {
    if (heightValue > 0) {
      cachedHeight.value = heightValue
    }
  })

  // Check for min/max visible
  let isMinVisible = computed(() => visibleFraction.value === 'min')
  let isMaxVisible = computed(() => visibleFraction.value === 'max')

  // Width that needs to be added due to skewing
  let additionalWidth = computed(
    () => Math.tan((degrees.value * Math.PI) / 180) * cachedHeight.value,
  )

  // The effective fraction to use for calculations
  let effectiveFraction = computed(() =>
    visibleSide.value === 'left' ? visibleFraction.value : 1 - visibleFraction.value,
  )

  // Horizontal position of the visible corner
  let startPosition = computed(() =>
    visibleSide.value === 'left'
      ? `${-1 * (1 - effectiveFraction.value) * additionalWidth.value}px`
      : '100%',
  )

  // Horizontal position of the upper edge
  let upperPosition = computed(() => {
    if (isMinVisible.value || isMaxVisible.value) {
      if (
        (visibleSide.value === 'left' && isMaxVisible.value) ||
        (visibleSide.value === 'right' && isMinVisible.value)
      ) {
        return `100%`
      } else {
        return `${additionalWidth.value}px`
      }
    }

    return `calc(${effectiveFraction.value * 100}% + ${
      effectiveFraction.value * additionalWidth.value
    }px)`
  })

  // Horizontal position of the lower edge
  let lowerPosition = computed(() => {
    if (isMinVisible.value || isMaxVisible.value) {
      if (
        (visibleSide.value === 'left' && isMaxVisible.value) ||
        (visibleSide.value === 'right' && isMinVisible.value)
      ) {
        return `calc(100% - ${additionalWidth.value}px)`
      } else {
        return `0`
      }
    }

    return `calc(${effectiveFraction.value * 100}% - ${
      (1 - effectiveFraction.value) * additionalWidth.value
    }px)`
  })

  // Resulting clip path
  let clipPath = computed(() => {
    if (!element.value) return 'polygon(0 0, 100% 0, 100% 100%, 0 100%)'

    return `polygon(${startPosition.value} 0, ${upperPosition.value} 0, ${lowerPosition.value} 100%, ${startPosition.value} 100%)`
  })

  return {
    css: {
      '--skew-effective-fraction': effectiveFraction,
      '--skew-start-position': startPosition,
      '--skew-upper-position': upperPosition,
      '--skew-lower-position': lowerPosition,
      '--skew-clip-path': readonly(
        ref(
          'polygon(var(--skew-start-position) 0, var(--skew-upper-position) 0, var(--skew-lower-position) 100%, var(--skew-start-position) 100%)',
        ),
      ),
    },
    clipPath,
  }
}
