// Like a computed reactive object (updated when dependencies change), but writable
// However, changes are discarded when dependencies change and the computed is updated

export function writableComputedReactive(getter) {
  let readonlyComputed = computed(getter)

  let value = ref(readonlyComputed.value)
  watch(readonlyComputed, readonlyComputedValue => {
    value.value = readonlyComputedValue
  })

  return toReactive(value)
}
