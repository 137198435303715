<script setup>
let props = defineProps({
  logos: Array,
})

let { data: logosHtml, pending } = await useWordpressData('wp-remote/logos/render', {
  body: props.logos.map(logo => ({
    link: logo.value.link,
    image: logo.value.image[0].id,
  })),
})
</script>

<template>
  <div class="container-wide">
    <div class="wpce-logos" v-if="!pending">
      <div v-for="logoHtml in logosHtml" v-html="logoHtml" />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.wpce-logos {
  --columns: 2;
  --gap: max(#{space(1)}, 2rem) max(#{space(1.5)}, 2.5rem);

  display: grid;
  grid-template-columns: repeat(var(--columns), 1fr);
  grid-gap: var(--gap);

  & > * {
    place-self: center;
  }

  @include mediaSM {
    --columns: 3;
  }

  @include mediaMD {
    --columns: 4;
  }

  @include mediaLG {
    --columns: 5;
  }
}
</style>
