// Impetus is not SSR ready, therefore we need webpack to conditionally import it
import { Impetus } from '@/assets/js/impetus'

export function useImpetus(
  source,
  { multiplier, friction = useImpetusFriction(), boundX, boundY, bounce, callback } = {},
) {
  source = ref(source)

  multiplier = ref(multiplier)
  friction = ref(friction)
  boundX = ref(boundX)
  boundY = ref(boundY)
  bounce = ref(bounce)
  callback = ref(callback)

  let internalUpdating = ref(false)
  let x = ref(0)
  let y = ref(0)
  let paused = ref(false)
  let destroyed = ref(false)

  let impetus

  watch(
    [source, bounce, callback],
    ([sourceValue, bounceValue, callbackValue]) => {
      if (!Impetus || !source.value) return

      impetus?.destroy()

      let initial = true
      impetus = new Impetus({
        source: sourceValue,
        initialValues: [x.value, y.value],
        boundX: boundX.value,
        boundY: boundY.value,
        friction: friction.value,
        multiplier: multiplier.value,
        bounce: bounceValue,
        update: (updatedX, updatedY) => {
          if (paused.value || destroyed.value) return

          internalUpdating.value = true
          x.value = updatedX
          y.value = updatedY

          requestAnimationFrame(() => {
            internalUpdating.value = false
          })

          if (!initial && typeof callbackValue === 'function') {
            callbackValue(updatedX, updatedY)
          }

          initial = false
        },
      })
    },
    {
      immediate: true,
    },
  )

  watch([x, y], ([xValue, yValue]) => {
    if (internalUpdating.value) return

    impetus?.setValues(xValue, yValue)
  })

  watch(multiplier, multiplierValue => {
    impetus?.setMultiplier(multiplierValue)
  })

  watch(boundX, boundXValue => {
    impetus?.setBoundX(boundXValue)
  })

  watch(boundY, boundYValue => {
    impetus?.setBoundY(boundYValue)
  })

  tryOnScopeDispose(() => {
    destroyed.value = true
    impetus?.destroy()
  })

  return {
    x,
    y,
    pause: () => {
      if (paused.value || destroyed.value) return

      paused.value = true
      impetus?.pause()
    },
    resume: () => {
      if (!paused.value || destroyed.value) return

      paused.value = false
      impetus?.resume()
    },
  }
}
