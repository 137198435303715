// Like a computed (updated when dependencies change), but writable
// However, changes are discarded when dependencies change and the computed is updated

export function writableComputed(getter) {
  let readonlyComputed = computed(getter)

  let value = ref(readonlyComputed.value)
  watch(readonlyComputed, readonlyComputedValue => {
    value.value = readonlyComputedValue
  })

  return value
}
