<template>
  <div ref="hero" class="parallax-hero" :class="{ visible: heroIsVisible }">
    <div ref="heroSky" class="hero-layer hero-layer-sky">
      <picture>
        <source src="/about-assets/hero/sky.webp" type="image/webp" />
        <img src="/about-assets/hero/sky.jpg" width="2560" height="3456" alt="" draggable="false" />
      </picture>
    </div>
    <div class="hero-text">
      <div class="container-wide">
        <HeroHeadline :text="title" highlight-first-line with-slash v-enter />
      </div>
    </div>
    <div ref="heroHorizon" class="hero-layer hero-layer-horizon" />
    <div ref="heroWalkers" class="hero-layer hero-layer-walkers" />
    <div ref="heroRocks" class="hero-layer hero-layer-rocks">
      <div class="hero-flicker-blocker" />
    </div>
  </div>
  <div class="parallax-hero-content">
    <div class="container-wide">
      <ContentBox>
        <div v-html="content" />
      </ContentBox>
    </div>
  </div>
</template>

<script setup>
import { checkWebpSupport } from '@/assets/js/util'

defineProps({
  title: String,
  content: String,
})

let hero = ref()
let heroSky = ref()
let heroHorizon = ref()
let heroWalkers = ref()
let heroRocks = ref()

let progressStore = useProgressStore()

if (!import.meta.env.SSR) {
  let imagesProgress = ref()
  progressStore.track(useProgress(imagesProgress))

  let assets = [
    '/about-assets/hero/sky.jpg',
    '/about-assets/hero/horizon.png',
    '/about-assets/hero/walkers.png',
    '/about-assets/hero/rocks.png',
  ]

  checkWebpSupport().then(supportsWebp => {
    let imagePreload = usePreload(
      assets.map(asset => (supportsWebp ? asset.replace(/\.(jpg|png)$/i, '.webp') : asset)),
    )

    until(imagePreload.isComplete)
      .toBe(true)
      .then(() => {
        imagesProgress.value = true
      })
  })
}

let timeline = useGsapTimeline()
  .add(
    useGsapTo(heroSky, {
      y: '-100%',
      top: '60%',
      ease: 'none',
    }),
    0,
  )
  .add(
    useGsapTo(heroHorizon, {
      y: '-100%',
      top: '70%',
      ease: 'none',
    }),
    0,
  )
  .add(
    useGsapTo(heroWalkers, {
      y: '-100%',
      top: '80%',
      ease: 'none',
    }),
    0,
  )
  .add(
    useGsapTo(heroRocks, {
      y: '-100%',
      top: '100%',
      ease: 'none',
    }),
    0,
  )

useGsapScrollTrigger(hero, {
  animation: timeline.instance,
  scrub: true,
  start: 'top top',
  end: 'bottom top',
})

let heroIsVisible = ref(true)
useIntersectionObserver(hero, ([entry]) => {
  heroIsVisible.value = entry.isIntersecting
})
</script>

<style lang="scss" scoped>
.parallax-hero {
  position: relative;
  height: calc(4 * var(--100lvh));
  overflow: hidden;
  opacity: 0;
  transition: opacity 300ms;
  pointer-events: none;

  &.visible {
    opacity: 1;
    pointer-events: all;
  }

  .hero-text {
    position: fixed;
    top: 40%;
    width: 100%;
    z-index: 5;

    @include mediaSM {
      top: 30%;
    }
  }

  .hero-layer {
    width: 100%;
    position: fixed;
    top: 0;
    z-index: -1;
    background-size: contain;
    background-position: top center;
    background-repeat: no-repeat;

    &.hero-layer-sky {
      height: 140%;

      img {
        width: auto;
        height: 100%;
        object-fit: cover;
        object-position: 70%;

        @include mediaSM {
          width: 100%;
          height: auto;
        }
      }
    }

    &.hero-layer-horizon {
      --width: 2560;
      --height: 1408;
      --factor: 1.5;

      background-image: url('/about-assets/hero/horizon.webp');
      background-size: calc(var(--factor) * 100%) auto;
      padding-top: calc(100% * var(--factor) * var(--height) / var(--width));
      height: 0;
      top: 100%;
      z-index: 10;

      @at-root :root.no-webp & {
        background-size: cover;
        background-image: url('/about-assets/hero/horizon.png');
      }

      @include mediaSM {
        --factor: 1;
      }
    }

    &.hero-layer-walkers {
      --width: 2560;
      --height: 3036;
      --factor: 1.75;

      background-image: url('/about-assets/hero/walkers.webp');
      background-size: calc(var(--factor) * 100%) auto;
      padding-top: calc(100% * var(--factor) * var(--height) / var(--width));
      height: 0;
      top: 130%;
      z-index: 30;

      @at-root :root.no-webp & {
        background-image: url('/about-assets/hero/walkers.png');
      }

      @include mediaSM {
        --factor: 1;
        background-size: cover;
      }
    }

    &.hero-layer-rocks {
      --width: 2560;
      --height: 1080;
      position: relative;
      background-image: url('/about-assets/hero/rocks.webp');
      background-size: cover;
      padding-top: calc(100% * var(--height) / var(--width));
      height: 0;
      top: 80%;
      z-index: 40;
      transform: translateY(-50%);

      @at-root :root.no-webp & {
        background-image: url('/about-assets/hero/rocks.png');
      }

      // Black rectangle to avoid scrub flickering effects when scrolling back up
      &::after {
        content: '';
        position: absolute;
        width: 100%;
        height: 600%;
        top: calc(100% - 1px);
        left: 0;
        background-color: var(--bg);
      }
    }
  }
}

.parallax-hero-content {
  --rocks-width: 2560;
  --rocks-height: 1080;

  position: relative;
  background-color: var(--bg);
  z-index: 1000;
  margin-top: calc(-50vw * var(--rocks-height) / var(--rocks-width));
  padding-bottom: calc(50vw * var(--rocks-height) / var(--rocks-width));
}
</style>
