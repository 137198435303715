import {
  getCurrentInstance,
  inject,
  onMounted,
  onUnmounted,
  onUpdated,
  provide,
  readonly,
  ref,
  watch,
} from 'vue'

let providerPrefix = 'parent-child-provider-'

/**
 * Use this to mark a parent component
 *
 * Options:
 * - accept: Which components are accepted as children, can be:
 *           - true (to accept all components, the default)
 *           - an imported component type
 *           - an array of imported component types
 *           - a predicate function being passed each component instance
 * - key:    Identifier of the parent-child relation.
 *           Useful to prevent interferences if multiple parent-child relations are intertwined.
 */
export function useAsParent({ accept = true, key = 'default' } = {}) {
  let childComponents = new Set()

  // Make a validator function that checks if the component is accepted
  let validator = () => true
  if (typeof accept === 'function') {
    validator = accept
  } else if (Array.isArray(accept)) {
    validator = component => accept.some(componentClass => component.type === componentClass)
  } else if (accept !== true) {
    validator = component => component.type === accept
  }

  // Get an array of child components, sorted by their DOM position
  function getSortedChildComponents() {
    let childComponentsCopy = [...childComponents]
    if (childComponentsCopy.length === 0) return childComponentsCopy

    let sorted = childComponentsCopy.sort((a, b) => {
      let aNode = a.vnode.el
      let bNode = b.vnode.el

      let position = aNode.compareDocumentPosition(bNode)
      if (position & Node.DOCUMENT_POSITION_FOLLOWING) return -1
      if (position & Node.DOCUMENT_POSITION_PRECEDING) return 1
      return 0
    })

    return sorted
  }

  let sortedChildComponents = ref(getSortedChildComponents())
  let instance = getCurrentInstance()

  provide(providerPrefix + key, {
    instance,
    accepts(component) {
      return validator(component)
    },
    register(child) {
      if (!childComponents.has(child)) {
        if (!validator(child)) {
          console.warn('Invalid child component: %o', child)
          return
        }

        childComponents.add(child)
        sortedChildComponents.value = getSortedChildComponents()
      }
    },
    deregister(child) {
      if (childComponents.has(child)) {
        childComponents.delete(child)
        sortedChildComponents.value = getSortedChildComponents()
      }
    },
    update() {
      let sorted = getSortedChildComponents()

      // Bail if component order has not changed
      if (
        sorted.length === sortedChildComponents.value.length &&
        sorted.every((child, i) => child.uid === sortedChildComponents.value[i].uid)
      ) {
        return
      }

      sortedChildComponents.value = sorted
    },
  })

  return readonly(sortedChildComponents)
}

/**
 * Use this to mark a child component
 *
 * Options:
 * - standalone:   Suppress warning if component is not used inside an according parent component
 * - watchUpdates: Whether to re-evaluate component order on render update
 *                 Should only be enabled if explicitly needed to avoid wasting CPU cycles
 * - hidden:       Whether the child component is currently hidden from being reported to the
 * - key:          Identifier of the parent-child relation.
 *                 Useful to prevent interferences if multiple parent-child relations are intertwined.
 */
export function useAsChild({
  standalone = false,
  watchUpdates = false,
  hidden = false,
  key = 'default',
} = {}) {
  let isMounted = false
  let hiddenRef = ref(hidden)
  let watchUpdatesRef = ref(watchUpdates)

  let instance = getCurrentInstance()
  let provider = inject(providerPrefix + key, undefined)

  if (!provider) {
    if (!standalone) {
      console.warn('useAsChild() must be used inside a useAsParent() component')
    }
    return
  }

  if (!provider.accepts(instance)) {
    if (!standalone) {
      console.warn('useAsChild() component was not accepted by its useAsParent() component')
    }
    return
  }

  let register = () => provider.register(instance)
  let deregister = () => provider.deregister(instance)

  watch(hiddenRef, isHidden => {
    if (isHidden) {
      deregister()
    } else if (isMounted) {
      register()
    }
  })

  onMounted(() => {
    isMounted = true
    if (hiddenRef.value) return
    register()
  })

  let stopOnUpdate
  watch(
    watchUpdatesRef,
    shouldWatchUpdates => {
      if (shouldWatchUpdates) {
        stopOnUpdate = onUpdated(() => {
          if (hiddenRef.value) return

          provider.update()
        })
      } else {
        stopOnUpdate?.()
      }
    },
    { immediate: true },
  )

  onUnmounted(() => {
    isMounted = false
    deregister()
  })

  return provider.instance
}
