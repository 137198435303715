export function useWheel(...args) {
  let target, listener
  if (args.length === 0) {
    throw new Error('Missing useWheel listener')
  } else if (args.length === 1) {
    listener = args[0]
  } else {
    target = args[0]
    listener = args[1]
  }

  let isWheelingRef = ref(false)
  let debouncedSetWheelingFalse = useDebounceFn(() => {
    isWheelingRef.value = false
  }, 200)

  let stop = useEventListener(
    target,
    'wheel',
    event => {
      event.preventDefault()

      isWheelingRef.value = true
      debouncedSetWheelingFalse()

      let delta = 0
      /*
       * The deltaX and deltaY values might be bigger than 0 or smaller than 0 at the same time
       * (you never scroll 100% vertically or 100% horizontally).
       * Therefore compare absolute values to get the bigger value to determine the used scroll axis.
       */
      if (Math.abs(event.deltaX) > Math.abs(event.deltaY)) {
        delta = event.deltaX
      } else {
        delta = event.deltaY
      }

      listener(delta)
    },
    { passive: false },
  )

  return {
    isWheeling: readonly(isWheelingRef),
    stop,
  }
}
