<template>
  <div class="content-box">
    <Slash class="content-box-slash" />
    <div class="content-box-wrap">
      <slot />
    </div>
  </div>
</template>

<script setup></script>

<style lang="scss" scoped>
.content-box {
  position: relative;
  max-width: var(--container-narrow-width);

  :deep(p:first-child) {
    margin-top: 0;
  }

  :deep(p:last-child) {
    margin-bottom: 0;
  }
}

.content-box {
  @include padding;
  --padding-x: 0px;
  --padding-y: 0px;
}

.content-box-slash {
  position: absolute;
  // left: var(--slash-offset-left, 4rem);
  margin-top: 0.1em;
  margin-left: -1.25em;
  --slash-size: 1.25em;
  --slash-color: var(--primary-color);
}
</style>
