<template>
  <div class="scroll" :style="{ '--scroll-progress': clampedProgress }">
    <div class="scroll-content">
      <slot />
    </div>
  </div>
</template>

<script setup>
import { clamp } from 'lodash-es'

let props = defineProps({
  progress: [Number, String],
})

let clampedProgress = computed(() => clamp(props.progress, 0, 1))
</script>

<style lang="scss" scoped>
.scroll {
  overflow: hidden;
}

.scroll-content {
  display: flow-root;
  transform: translateY(calc(-100% * (1 - var(--scroll-progress, 0))));
}
</style>
