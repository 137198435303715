<script setup>
import { clamp } from 'lodash-es'
import { uuid } from '@/assets/js/util'
import gsap, { Power2 } from 'gsap'
import ScrollTo from 'gsap/ScrollToPlugin'

let SkewMask = resolveComponent('SkewMask')

if (!import.meta.env.SSR) {
  gsap.registerPlugin(ScrollTo)
}

let props = defineProps({
  intro: String,
  categories: Array,
  items: Array,
  options: Object,
})

const introAnimationDuration = 400

let screen = useScreen()

let params = new URLSearchParams()
if (props.items?.length > 0) {
  for (let item of props.items) {
    params.append('id[]', item.id)
  }
} else if (props.categories?.length > 0) {
  for (let category of props.categories) {
    params.append('category[]', category.id)
  }
}
let queryString = params.toString().length > 0 ? `?${params.toString()}` : ''

let { data: rawItems, pending } = await useWordpressData(`wp-remote/projects${queryString}`)

let dummyItems = [
  'x1.jpg',
  'x2.jpg',
  'x3.jpg',
  'x4.jpg',
  'x5.jpg',
  'x6.jpg',
  'x7.jpg',
  'y1.jpg',
  'y2.jpg',
  'y3.jpg',
  'y4.jpg',
  'y5.jpg',
  'y6.jpg',
  'y7.jpg',
  'y8.jpg',
  'y9.jpg',
  'y10.jpg',
  'y11.jpg',
  'y12.jpg',
].map((file, index) => ({
  title: `Dummy Image ${index + 1}`,
  slug: '-' + uuid(),
  hero: {
    image: `/dummy-images/${file}`,
    focus: [0.5, 0.5],
    video: null,
  },
  tagline: `Dummy Image\nNumber ${index + 1}`,
  metadata: [],
}))

let items = computed(() => [...rawItems.value, ...(props.options.dummy ? dummyItems : [])])

let initialRenderStore = useInitialRenderStore()
let showIntro = useSessionStorage('show-intro', initialRenderStore.isInitial)

let designStore = useDesignStore()
let introComplete = ref(!showIntro.value)
let skipping = ref(false)
let rawScroll = ref(0)
let wrapperRef = ref()
let introRef = ref()

let isGrabbing = ref(false)
let { width } = useElementSize(introRef)
let { height: windowHeight } = useWindowSize()
let scroll = useClamp(rawScroll, 0, width)

let impetus = useImpetus(introRef, {
  boundX: computed(() => [-width.value, 0]),
  callback() {
    isGrabbing.value = true
  },
})

let invertedImpetusX = computed({
  get: () => -impetus.x.value,
  set: value => {
    impetus.x.value = -value
  },
})

syncRef(rawScroll, invertedImpetusX)

let { isWheeling, stop: stopWheel } = useWheel(introRef, delta => {
  requestAnimationFrame(() => {
    rawScroll.value = rawScroll.value + delta
  })
})

watch(isWheeling, value => {
  if (value) {
    isGrabbing.value = false
    impetus.pause()
  } else {
    impetus.resume()
  }
})

watch(scroll, scrollValue => {
  wrapperRef.value.scrollLeft = scrollValue
})

let velocity = useVelocity(scroll)

let plannedSliderOffset = computed(
  () => designStore.derived.triangleHeightFraction * windowHeight.value * 0.5,
)
let sliderOffset = ref(initialRenderStore.isInitial ? 0 : plannedSliderOffset.value)
let sliderOffsetAnimationDuration = ref(0)

let sliderRef = ref()
let { stop: stopVisibilityTrigger } = useIntersectionObserver(sliderRef, ([{ isIntersecting }]) => {
  gsap.killTweensOf(rawScroll)

  if (!showIntro.value) {
    sliderOffset.value = plannedSliderOffset.value
    isGrabbing.value = false
    impetus.pause()
    stopVisibilityTrigger()
    stopWheel()
    return
  }

  if (isIntersecting) {
    // For future visits during the same session, hide intro
    showIntro.value = false

    isGrabbing.value = false
    impetus.pause()
    stopVisibilityTrigger()
    stopWheel()

    // Calculate animated scroll duration based on current velocity
    let scrollDistance = window.innerWidth
    let scrollDuration = clamp(scrollDistance / velocity.value, 0.75, 3)

    setTimeout(() => {
      sliderOffsetAnimationDuration.value = scrollDuration / 2
      sliderOffset.value = plannedSliderOffset.value
    }, scrollDuration * 1000 * 0.5)

    gsap.to(wrapperRef.value, {
      duration: scrollDuration,
      scrollTo: { x: width.value },
      ease: Power2.easeOut,
      onComplete() {
        setTimeout(() => {
          introComplete.value = true
          nextTick(() => {
            wrapperRef.value.scrollLeft = 0
          })
        }, scrollDuration * 1000 * 0.5)
      },
    })
  }
})

function skip() {
  skipping.value = true
  sliderOffset.value = plannedSliderOffset.value

  setTimeout(() => {
    introComplete.value = true
    skipping.value = false
  }, introAnimationDuration)
}
</script>

<template>
  <ClientOnly>
    <div class="work-slider-wrapper" :class="{ 'intro-complete': introComplete }" ref="wrapperRef">
      <div v-if="!skipping && !introComplete" class="work-slider-intro" ref="introRef">
        <div class="work-slider-logo">
          <img src="@/assets/img/logo.svg" width="484" height="115" alt="module+ Logo" />
        </div>
        <div class="work-slider-controls">
          <button type="button" @click="skip" class="skip-button">Skip</button>
          <button type="button" @click="skip" class="control-button">
            <!-- prettier-ignore -->
            <svg aria-hidden="true" focusable="false" width="16" height="27" viewBox="0 0 16 27" xmlns="http://www.w3.org/2000/svg"><polygon points="0 0 0 6 13.5 16 27 6 27 3.55271368e-15 13.5 10" fill="currentColor" transform="rotate(-90 13.5 13.5)" fill-rule="evenodd"/></svg>
            <span class="sr-only">Nächstes Projekt</span>
          </button>
        </div>
        <component
          :is="screen.sm.value ? SkewMask : 'div'"
          class="work-slider-slogan-mask"
          visible-fraction="max"
          visible-side="right"
        >
          <div class="work-slider-slogan">
            <Slash v-if="screen.sm.value" class="work-slider-slash" /> {{ intro }}
            <Slash class="work-slider-slash" />
          </div>
        </component>
      </div>
      <div class="work-slider-slider" ref="sliderRef">
        <transition name="fade">
          <WorkSlider
            v-if="!skipping && !pending"
            :items="items"
            :disabled="!introComplete"
            :offset="sliderOffset"
            :offset-animation-duration="sliderOffsetAnimationDuration"
          >
            <slot />
          </WorkSlider>
        </transition>
      </div>
    </div>
  </ClientOnly>
</template>

<style lang="scss" scoped>
.work-slider-wrapper {
  position: relative;
  width: 100%;
  height: var(--100dvh);
  overflow: hidden;
  display: flex;

  & > :deep(*) {
    flex-shrink: 0;
  }
}

.work-slider-slider {
  position: relative;
  z-index: 1;

  @at-root .work-slider-wrapper:not(.intro-complete) &::before {
    content: '';
    position: absolute;
    inset: 0;
    z-index: 1;
  }
}

.work-slider-intro {
  --slash-color: var(--primary-color);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 3rem;
  width: 101vw;

  @include mediaSM {
    display: block;
    max-width: none;
    width: initial;
  }

  & > * {
    flex-shrink: 0;
  }
}

.work-slider-slogan-mask {
  z-index: 1;
  max-width: max(75vw, 280px);

  @include mediaSM {
    max-width: none;
    height: 100%;
    margin-left: calc(100vw - var(--triangle-height-fraction) * 0.4 * var(--100dvh));
    padding-right: calc(var(--triangle-height-fraction) * var(--100dvh));
    margin-right: calc(-1 * var(--triangle-height-fraction) * var(--100dvh));
  }
}
.work-slider-slogan {
  max-width: 100%;
  font-size: 8vw;
  font-weight: bold;
  line-height: 1;
  background-color: #000;
  margin-right: calc(-1 * var(--triangle-height-fraction) * var(--100dvh));

  @include mediaSM {
    max-width: none;
    display: flex;
    align-items: center;
    gap: 0.2em;
    height: 100%;
    padding-left: calc(var(--triangle-height-fraction) * var(--100dvh) / 2 - 0.15em);
    padding-right: calc(var(--triangle-height-fraction) * var(--100dvh));
  }
}

.work-slider-slash {
  margin-top: -0.2em;
  margin-bottom: -0.1em;
  display: inline-block;
}

.work-slider-logo {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 280px;

  @include mediaSM {
    position: fixed;
    max-width: none;
    width: 100%;
    height: 100%;
  }

  img {
    height: auto;
    width: min(80vw, 275px);

    @include mediaSM {
      height: 9vw;
      width: auto;
      margin-top: -4vw;
    }
  }
}

.work-slider-controls {
  position: fixed;
  z-index: 100;
  bottom: 2rem;
  right: 2rem;
  user-select: none;
  display: flex;
  justify-content: center;
  align-items: center;

  .skip-button {
    @include reset-form-ui;
    text-transform: uppercase;
    margin-right: 3rem;
    font-size: 0.8em;
    cursor: pointer;
  }

  .control-button {
    @include reset-form-ui;

    --default-scroll-button-size: 60px;
    position: relative;
    color: var(--scroll-button-color, var(--primary-color));
    border-radius: 50%;
    width: var(--scroll-button-size, var(--default-scroll-button-size));
    height: var(--scroll-button-size, var(--default-scroll-button-size));
    background-color: var(--scroll-button-background, rgb(0 0 0 / 30%));
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: opacity ease calc(v-bind('introAnimationDuration') * 1ms);

    @include mediaSM {
      --default-scroll-button-size: 80px;
    }

    &:disabled {
      opacity: 0;
      pointer-events: none;
    }
  }

  & > * {
    margin: 15px;
  }
}
</style>
