import { uuid } from '@/assets/js/util'

export function useProgress(progressInput, label) {
  let isIndeterminate,
    isComplete,
    hasLabel,
    isAborted,
    isFinished,
    isBoolean,
    numericProgress,
    disposeHook

  let scope = effectScope(true)
  scope.run(() => {
    disposeHook = createEventHook()

    let progress = ref(progressInput)
    label = ref(label)

    isAborted = ref(false)
    isIndeterminate = computed(() => progress.value == null || progress.value === false)
    isBoolean = computed(() => typeof progress.value === 'boolean')
    isComplete = computed(() => progress.value === 1 || progress.value === true)
    hasLabel = computed(() => typeof label === 'string')
    numericProgress = computed(() => {
      if (isBoolean.value) {
        return isComplete.value ? 1 : 0
      } else {
        return progress.value
      }
    })

    isFinished = computed(() => isComplete.value || isAborted.value)
  })

  tryOnScopeDispose(() => {
    isAborted.value = true
    disposeHook.trigger()
    scope.stop()
  })

  return {
    id: uuid(),
    progress: numericProgress,
    label: readonly(label),

    isIndeterminate,
    isComplete,
    isAborted: readonly(isAborted),
    isFinished,
    hasLabel,

    done() {
      return until(isComplete).toBe(true)
    },

    onDispose: disposeHook.on,
  }
}
