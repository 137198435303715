export default {
  props: {
    if: {
      type: [Boolean, Function],
      default: true,
    },
  },
  setup(props, { slots }) {
    return () => {
      const functionalUnwrap = typeof props.if === 'function'
      const children = slots.default()

      if (!functionalUnwrap && !props.if) return children

      let reducer = functionalUnwrap
        ? (carry, current, index, array) => [
            ...carry,
            current.children && props.if(current, index, array) ? current.children : current,
          ]
        : (carry, current) => [...carry, current.children || current]

      return children.reduce(reducer, [])
    }
  },
}
