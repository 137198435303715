<!--
  The reason for this component is that, for some reason, 'resolveComponent' does not work
  inside the WpceRenderer.jsx setup hook. Therefore, we receive components here and pass them down.
-->

<script setup>
defineProps({
  tree: Object,
})

let elements = {
  'about-hero': resolveComponent('WpceAboutHero'),
  'about-videos': resolveComponent('WpceAboutVideos'),
  'blog-posts': resolveComponent('WpceBlogPosts'),
  box: resolveComponent('WpceBox'),
  buttons: resolveComponent('WpceButtons'),
  column: resolveComponent('WpceColumn'),
  'contact-form': resolveComponent('WpceContactForm'),
  'facts-list': resolveComponent('WpceFactsList'),
  'global-element': resolveComponent('WpceGlobalElement'),
  'hero-headline': resolveComponent('WpceHeroHeadline'),
  'hero-image': resolveComponent('WpceHeroImage'),
  'hero-video': resolveComponent('WpceHeroVideo'),
  html: resolveComponent('WpceHtml'),
  image: resolveComponent('WpceImage'),
  logos: resolveComponent('WpceLogos'),
  'masonry-section': resolveComponent('WpceMasonrySection'),
  'masonry-section-item': resolveComponent('WpceMasonrySectionItem'),
  'project-slider': resolveComponent('WpceProjectSlider'),
  root: resolveComponent('WpceRoot'),
  section: resolveComponent('WpceSection'),
  spacer: resolveComponent('WpceSpacer'),
  swiper: resolveComponent('WpceSwiper'),
  testimonials: resolveComponent('WpceTestimonials'),
  text: resolveComponent('WpceText'),
  video: resolveComponent('WpceVideo'),
}
</script>

<template>
  <WpceRenderer v-if="tree" :tree="tree" :elements="elements" />
</template>
