<template>
  <div aria-hidden="true" :style="{ '--aspect-ratio': aspectRatio }">
    <div class="strip-video" :class="{ 'all-loaded': allLoaded, 'first-loaded': firstLoaded }">
      <ProgressBar :value="loadingProgress" class="loading-progress" overlay />
      <template v-for="(image, index) in identifiableImages" :key="image.src">
        <picture
          class="strip-video-picture"
          :class="{ active: image.src === activeImage.src }"
          :data-index="index"
        >
          <source v-if="image.webp" :srcset="image.webp" type="image/webp" />
          <img
            :ref="el => imageElements.set(image, el)"
            :src="image.src"
            :srcset="image.webp ? '/images/portrait/portrait.webp' : ''"
            :width="width"
            :height="height"
            class="strip-video-img"
            @load="onImageReady(image)"
            @error="onImageReady(image)"
            alt=""
            draggable="false"
          />
        </picture>
      </template>
    </div>
  </div>
</template>

<script setup>
import { uuid } from '@/assets/js/util'

let props = defineProps({
  progress: Number,
  images: Array,
  width: Number,
  height: Number,
  webp: [Boolean, Function],
})

let aspectRatio = computed(() => props.height / props.width)

let getWebP = computed(() =>
  props.webp === true ? url => url.replace(/\.[a-z]+$/, '.webp') : props.webp,
)

let identifiableImages = computed(() =>
  props.images.map(image => ({
    src: image,
    webp: props.webp ? getWebP.value(image) : undefined,
  })),
)
let imageElements = ref(new Map())
let imageElementsCount = computed(() => imageElements.value.length)

let loaded = ref(new Set())
function markLoaded(image) {
  loaded.value.add(image.src)
}

function onImageReady(image) {
  markLoaded(image)
}

onMounted(() => {
  for (let [image, element] of imageElements.value) {
    if (element.naturalWidth > 0) {
      markLoaded(image)
    }
  }
})

let activeIndex = computed(() => Math.round(props.progress * (props.images.length - 1)))
let activeImage = computed(() => identifiableImages.value[activeIndex.value])

let loadingProgress = computed(() => loaded.value.size / props.images.length)
let firstLoaded = computed(
  () => loaded.value.size > 0 && loaded.value.has(identifiableImages.value[0].src),
)
let allLoaded = computed(() => loaded.value.size === identifiableImages.value.length)
</script>

<style lang="scss" scoped>
.strip-video {
  position: relative;
  box-sizing: content-box;
  padding-top: calc(var(--aspect-ratio) * 100%);
  height: 0;
  overflow: hidden;

  .loading-progress {
    opacity: 1;
    transition: opacity 800ms;
  }

  &.all-loaded .loading-progress {
    opacity: 0;
  }

  .strip-video-picture {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
  }

  &:not(.all-loaded) .strip-video-picture {
    transition: opacity 800ms;
  }

  &.first-loaded:not(.all-loaded) .strip-video-picture[data-index='1'] {
    opacity: 0.5;
  }

  &.all-loaded .strip-video-picture.active {
    opacity: 1;
  }

  .strip-video-img {
    max-width: 100%;
    height: auto;
  }
}
</style>
