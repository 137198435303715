export function useSingleClick(element, callback, { threshold = 200 } = {}) {
  let clicked = autoResetRef(0, threshold + 50)

  useEventListener(element, 'click', event => {
    clicked.value++

    if (clicked.value === 1) {
      useTimeoutFn(() => {
        if (clicked.value === 1) {
          clicked.value = 0
          callback(event)
        }
      }, threshold)
    }
  })
}
