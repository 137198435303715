<script setup>
let props = defineProps({
  items: Array,
  columns: Number,
  itemKey: [String, Function],
  gap: {
    type: [Number, String],
    required: false,
  },
})

let itemKeyGetter = computed(() =>
  typeof props.itemKey === 'string' ? item => item[props.itemKey] : props.itemKey,
)

let columnsWithItems = computed(() => {
  let columns = []
  for (let i = 0; i < props.items.length; i++) {
    let columnIndex = i % props.columns
    if (columns.length <= columnIndex) columns.push({ column: i + 1, items: [] })

    columns[columnIndex].items.push(props.items[i])
  }

  return columns
})

let shouldBalance = computed(() => heightsById.size > 0 && props.columns.length > 1)

let balancedColumnsWithItems = computed(() => {
  if (!shouldBalance.value) return columnsWithItems.value

  let columns = []

  for (let i = 0; i < props.columns; i++) {
    columns.push({ column: i + 1, items: [], height: 0 })
  }
  let lowestColumn = columns[0]

  for (let i = 0; i < props.items.length; i++) {
    let itemId = itemKeyGetter.value(props.items[i])
    lowestColumn.height += heightsById.get(itemId) ?? 0
    lowestColumn.items.push(props.items[i])

    lowestColumn = [...columns].sort((a, b) => a.height - b.height)[0]
  }

  return columns
})

let resizeObserver = import.meta.env.SSR
  ? undefined
  : new ResizeObserver(entries => {
      for (let entry of entries) {
        if (idsByElements.has(entry.target)) {
          let id = idsByElements.get(entry.target)
          heightsById.set(id, entry.contentRect.height)
        }
      }
    })

let elementsById = reactive(new Map())
let idsByElements = reactive(new Map())
let heightsById = reactive(new Map())

function assignElement(id, element) {
  if (elementsById.has(id)) {
    let oldElement = elementsById.get(id)
    idsByElements.delete(oldElement)
    resizeObserver.unobserve(oldElement)
  }

  if (!element) {
    elementsById.delete(id)
    heightsById.delete(id)
  } else {
    idsByElements.set(element, id)
    elementsById.set(id, element)

    resizeObserver.observe(element)
  }
}

onUnmounted(() => {
  resizeObserver.disconnect()
})
</script>

<template>
  <div class="masonry" :style="{ '--gap': typeof gap === 'number' ? `${gap}px` : gap }">
    <ClientOnly>
      <template
        v-for="{ column, items } in balancedColumnsWithItems"
        :key="`balanced-column-${column}`"
      >
        <div class="masonry-column">
          <template v-for="item in items" :key="`item-balanced-${itemKeyGetter(item)}`">
            <div
              class="masonry-item"
              v-enter
              :ref="element => assignElement(itemKeyGetter(item), element)"
            >
              <slot :item="item" />
            </div>
          </template>
        </div>
      </template>

      <template #fallback>
        <!-- Server-side rendering of masonry -->
        <template v-for="{ column, items } in columnsWithItems" :key="`column-${column}`">
          <div class="masonry-column">
            <template v-for="item in items" :key="`item-unbalanced-${itemKeyGetter(item)}`">
              <div class="masonry-item" v-enter>
                <slot :item="item" />
              </div>
            </template>
          </div>
        </template>
      </template>
    </ClientOnly>
  </div>
</template>

<style lang="scss">
.masonry {
  --gap: #{space(1)};

  display: grid;
  grid-template-columns: repeat(v-bind('columns'), 1fr);
  grid-gap: var(--gap);
  align-items: flex-start;

  .masonry-item {
    width: 100%;
  }

  .masonry-column {
    min-width: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: var(--gap);
  }
}
</style>
