<template>
  <component :is="isInternal ? NuxtLink : 'a'" class="arrow-link" v-bind="attrs">
    <!-- prettier-ignore -->
    <svg class="arrow-link-icon" aria-hidden="true" focusable="false" width="8" height="14" viewBox="0 0 8 14" xmlns="http://www.w3.org/2000/svg"><polyline points="2.85245004 0 0.0218998084 0 5.01943608 7.16125654 0 14 2.82507528 14 8 7.18324607 2.85245004 0" fill="currentColor" fill-rule="nonzero"/></svg>

    <slot />
  </component>
</template>

<script setup>
let NuxtLink = resolveComponent('NuxtLink')

let props = defineProps({
  href: null,
  newTab: null,
})

let isInternal = computed(() => typeof props.href !== 'string' || props.href.startsWith('/'))
let targetAttrs = computed(() =>
  typeof props.newTab !== 'boolean'
    ? isInternal.value
      ? {}
      : {
          target: '_blank',
          rel: 'noopener noreferrer',
        }
    : props.newTab === true
    ? {
        target: '_blank',
        rel: 'noopener noreferrer',
      }
    : {},
)
let attrs = computed(() => {
  let result = {
    ...targetAttrs.value,
  }

  if (isInternal.value) {
    result.to = props.href
  } else {
    result.href = props.href
  }

  return result
})
</script>

<style lang="scss" scoped>
.arrow-link {
  color: var(--primary-color);
  text-decoration: none;
  font-weight: bold;

  .arrow-link-icon {
    height: var(--arrow-link-icon-size, 0.65em);
    width: auto;
    margin-right: 0.5em;
    margin-bottom: var(--arrow-link-icon-offset, 0px);
  }
}
</style>
