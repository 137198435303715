<template>
  <div class="sidebar-page">
    <MenuButton v-model:menu-visible="menu.active" @keydown.escape.prevent="menu.active = false" />
    <Menu v-model:visible="menu.active" />

    <div class="sidepar-page-wrapper" :inert="menu.active ? '' : null">
      <main class="sidebar-page-main">
        <slot />
      </main>
      <Sidebar v-show="screen.lg.value" />
      <Footer class="bottom-footer" />
    </div>
  </div>
</template>

<script setup>
let screen = useScreen()

let menu = useMenuStore()
let designStore = useDesignStore()

designStore.setScoped({
  background: designStore.tokens.primaryColor,
  foreground: '#fff',
  selectionBackground: '#000d',
  selectionForeground: '#fff',
  scrollbarColor: '#fff',
})

let progress = ref()
let progressStore = useProgressStore()
progressStore.track(useProgress(progress, 'sidebar layout'))

// No live applicance of tokens is needed as those are reactive, just set server-side to avoid flickering
designStore.applyAsCssVars({ live: true })

onMounted(() => {
  progress.value = true
})
</script>

<style lang="scss" scoped>
.sidebar-page {
  --sidebar-width: 550px;
  --overlap: 5vw;

  @media (min-height: 800px) {
    --sidebar-width: 650px;
    --overlap: 5vw;
  }

  font-size: 20px;
  --input-text-color: #000;
  --input-border-color: #fff;
  --input-placeholder-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}

.sidepar-page-wrapper {
  --max-width: 1530px;

  box-sizing: border-box;

  @include mediaLG {
    max-width: var(--max-width);
    padding-left: var(--container-padding);
    display: grid;
    grid-template-columns:
      1fr
      calc(var(--sidebar-width) - (100vw - var(--max-width)) / 2 - var(--overlap));
  }
}

.sidebar-page-main {
  z-index: 1;
  padding-top: 6rem;
  padding-bottom: 6rem;
  padding-left: var(--container-padding);
  padding-right: var(--container-padding);

  @include mediaLG {
    padding-left: 0;
  }
}

.bottom-footer {
  @media (min-width: 1400px) {
    display: none;
  }
}
</style>
