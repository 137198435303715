<script setup>
let props = defineProps({
  level: {
    type: Number,
    default: 2,
  },
  text: {
    type: String,
    required: false,
  },
  highlightFirstLine: Boolean,
  highlightLastLine: Boolean,
  withSlash: Boolean,
})

let textLines = computed(() => (props.text ? props.text.trim().split('\n') : []))
let highlightedFirstLines = computed(() =>
  props.highlightFirstLine ? textLines.value.slice(0, 1) : [],
)
let highlightedLastLines = computed(() =>
  props.highlightLastLine ? textLines.value.slice(-1) : [],
)
let normalLines = computed(() =>
  textLines.value.slice(props.highlightFirstLine ? 1 : 0, props.highlightLastLine ? -1 : undefined),
)

watch([() => props.highlightFirstLine, () => props.text], ([highlight, text]) => {
  if (highlight && !text) {
    console.warn('Cannot use the "highlightFirstLine" flag without a "text" prop.')
  }
})
</script>

<template>
  <component :is="`h${level}`" class="hero-headline">
    <template v-if="text">
      <template v-if="withSlash">
        <div v-if="highlightedFirstLines.length > 0" class="highlighted-lines">
          <div v-for="(line, index) in highlightedFirstLines">
            <template
              v-if="
                normalLines.length === 0 &&
                highlightedLastLines.length === 0 &&
                index === highlightedFirstLines.length - 1
              "
            >
              <TextWithPendingSlash :text="line" class="hero-slash" />
            </template>
            <template v-else>
              {{ line }}
            </template>
          </div>
        </div>
        <div v-if="normalLines.length > 0" class="normal-lines">
          <div v-for="(line, index) in normalLines">
            <template v-if="highlightedLastLines.length === 0 && index === normalLines.length - 1">
              <TextWithPendingSlash :text="line" class="hero-slash" />
            </template>
            <template v-else>
              {{ line }}
            </template>
          </div>
        </div>
        <div v-if="highlightedLastLines.length > 0" class="highlighted-lines">
          <div v-for="(line, index) in highlightedLastLines">
            <template v-if="index === highlightedLastLines.length - 1">
              <TextWithPendingSlash :text="line" class="hero-slash" />
            </template>
            <template v-else>
              {{ line }}
            </template>
          </div>
        </div>
      </template>
      <template v-else>
        <div v-if="highlightedFirstLines.length > 0" class="highlighted-lines">
          <div v-for="line in highlightedFirstLines">
            {{ line }}
          </div>
        </div>
        <div v-if="normalLines.length > 0" class="normal-lines">
          <div v-for="line in normalLines">
            {{ line }}
          </div>
        </div>
        <div v-if="highlightedLastLines.length > 0" class="highlighted-lines">
          <div v-for="line in highlightedLastLines">
            {{ line }}
          </div>
        </div>
      </template>
    </template>
    <template v-else> <slot /><Slash v-if="withSlash" class="hero-slash" /> </template>
  </component>
</template>

<style lang="scss" scoped>
.hero-headline {
  font-size: var(--hero-headline-size, calc(6vw + 1.25rem));
  line-height: var(--hero-headline-line-height, 1.15);
  margin: 0;
  font-weight: bold;
  white-space: pre-wrap;

  @include mediaSM {
    line-height: var(--hero-headline-line-height, 1);
  }
}

:deep(.hero-slash) {
  margin-bottom: -0.15em;
  margin-left: 0.25em;
  --slash-display: inline-block;
  --slash-size: 1em;
  --slash-color: var(--primary-color);
}

.hero-slash-break-preventer {
  white-space: nowrap;
}

.highlighted-lines {
  color: var(--primary-color);
}
</style>
