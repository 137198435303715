<script setup>
let props = defineProps({
  video: Array,
  poster: Array,
  autoplay: {
    type: [Boolean, String],
    default: false,
    validator: v => ['intersect', true, false].includes(v),
  },
  muted: Boolean,
  showControls: {
    type: [Boolean, String],
    default: 'hover',
    validator: v => ['interaction', 'hover', 'always', false].includes(v),
  },
})

let { data: videoUrl, pending } = await useWordpressData(
  `wp-remote/attachment/url/${props.video[0].id}`,
)

let poster =
  props.poster?.length > 0
    ? await useWordpressData(`wp-remote/image/url/${props.poster[0].id}`)
    : undefined

let videoRef = ref()
let mediaControls = computed(() => videoRef.value?.mediaControls)
let pausedManually = computed(() => videoRef.value?.pausedManually)

defineExpose({
  mediaControls,
  pausedManually,
  autoplay: toRef(props, 'autoplay'),
})
</script>

<template>
  <VideoPlayer
    ref="videoRef"
    v-if="!pending"
    v-bind="{ src: videoUrl, autoplay, muted, showControls }"
    :poster="poster && poster.data"
  />
</template>

<style lang="scss" scoped></style>
