import gsap from 'gsap'
import ScrollTrigger from 'gsap/ScrollTrigger'
import { toRawDeep } from '@/assets/js/util'

if (!import.meta.env.SSR) {
  gsap.registerPlugin(ScrollTrigger)
}

export function useGsapScrollTrigger(element, options = {}) {
  element = ref(element)
  options = reactive(options)

  let defaultOptions = {
    scrub: true,
    invalidateOnRefresh: true,
    start: 'top bottom',
    end: 'bottom top',
  }

  let mergedOptions = computed(() => ({
    ...defaultOptions,
    ...options,
  }))

  let result = reactive({
    active: false,
    progress: 0,
    direction: 0,
    // velocity: 0
  })

  if (import.meta.env.SSR) return result

  let scrollTrigger = ref()
  watch(
    [element, mergedOptions],
    () => {
      scrollTrigger.value?.kill()

      if (!element.value) return

      // Move actual scroll trigger creation to a later callstack,
      // otherwise it will use incorrect measurments after SPA route changes
      setTimeout(() => {
        scrollTrigger.value = markRaw(
          ScrollTrigger.create({
            ...mergedOptions.value,
            trigger: element.value,
            onToggle: self => {
              result.active = self.isActive
            },
            onUpdate: self => {
              result.progress = self.progress
              result.direction = self.direction
              // result.velocity = self.getVelocity()
              options.onUpdate?.(self)
            },
          }),
        )
      }, 100)
    },
    { immediate: true },
  )

  tryOnScopeDispose(() => {
    scrollTrigger.value?.kill()
  })

  return shallowReadonly(toRefs(result))
}

function useGsapTween(element, method, ...args) {
  element = ref(element)

  let tween = ref()

  if (import.meta.env.SSR) return tween

  watch(
    [element, ...args],
    ([elementValue, ...argsValues]) => {
      tween.value?.kill()

      if (!elementValue) {
        tween.value = undefined
        return
      }

      tween.value = markRaw(gsap[method](elementValue, ...argsValues))
    },
    { immediate: true },
  )

  tryOnScopeDispose(() => {
    tween.value?.kill()
  })

  return tween
}

export function useGsapFrom(element, options) {
  return useGsapTween(element, 'from', reactive(options))
}

export function useGsapTo(element, options) {
  return useGsapTween(element, 'to', reactive(options))
}

export function useGsapFromTo(element, fromOptions, toOptions) {
  return useGsapTween(element, 'fromTo', reactive(fromOptions), reactive(toOptions))
}

export function useGsapSet(element, options) {
  return useGsapTween(element, 'set', options)
}

export function useGsapTimeline(options = {}) {
  if (import.meta.env.SSR) {
    return {
      add() {
        return this
      },
      remove() {
        return this
      },
      instance: ref(),
    }
  }

  options = reactive(options)
  let mergedOptions = computed(() => ({
    paused: true,
    ...options,
  }))

  let timeline = ref()

  let tweens = new Set()
  let tweensDurations = new WeakMap()

  function addTweens() {
    for (let tween of tweens) {
      timeline.value.add(tween)
    }
  }

  function add(tween, position) {
    tween = ref(tween)
    position = ref(position)

    watch(
      [tween, position],
      ([tweenValue, positionValue], [oldTweenValue]) => {
        if (oldTweenValue) {
          timeline.value?.remove(oldTweenValue)
          tweens.delete(oldTweenValue)
          tweensDurations.delete(oldTweenValue)
        }

        if (tweenValue) {
          tweens.add(tweenValue)
          tweensDurations.set(tweenValue, tween.value)

          timeline.value?.add(tweenValue, positionValue)
        }
      },
      { immediate: true },
    )

    return result
  }

  function remove(tween) {
    let tweenValue = unref(tween)

    timeline.value?.remove(tweenValue)
    tweens.delete(tweenValue)
    tweensDurations.delete(tweenValue)

    return result
  }

  let result = { add, remove, instance: timeline }

  watch(
    mergedOptions,
    () => {
      timeline.value?.kill()
      timeline.value = markRaw(gsap.timeline(mergedOptions.value))
      addTweens()
    },
    { immediate: true },
  )

  tryOnScopeDispose(() => {
    timeline.value?.kill()
  })

  return result
}
