/**
 * TypeScript port of https://github.com/chrisbateman/impetus/
 * Functionally equivalent, but written in ES2022 + strongly typed with TypeScript
 * Also has a fix for https://github.com/chrisbateman/impetus/issues/46 by
 * just cutting the bounce animation after a certain timeout.
 */

const stopVelocityThresholdDefault = 0.3
const stopTimeThresholdDefault = 1000
const bounceDeceleration = 0.04
const bounceAcceleration = 0.11

export type ImpetusCallback = (this: Node, x: number, y: number) => void

export type ImpetusOptions = {
  source: Node
  update: ImpetusCallback
  multiplier: number
  friction: number
  initialValues: [number, number] | undefined
  boundX: [number, number] | undefined
  boundY: [number, number] | undefined
  bounce: boolean
}

export type NormalizedEvent = {
  x: number
  y: number
  id: number | undefined
}

export class Impetus {
  #element: Node
  #boundXmin: number | undefined
  #boundXmax: number | undefined
  #boundYmin: number | undefined
  #boundYmax: number | undefined
  #pointerLastX = 0
  #pointerLastY = 0
  #pointerCurrentX = 0
  #pointerCurrentY = 0
  #pointerId: number | undefined
  #deceleratingVelocityX = 0
  #deceleratingVelocityY = 0
  #deceleratingStartTime = 0
  #stopVelocityThreshold: number
  #stopTimeThreshold: number

  #targetX = 0
  #targetY = 0
  #ticking = false
  #pointerActive = false
  #paused = false
  #decelerating = false
  #trackingPoints: { x: number; y: number; time: number }[] = []
  #updateCallback: ImpetusCallback

  #bounce: boolean
  #multiplier: number
  #friction: number

  /**
   * Removes all events set by this instance during runtime
   */
  #cleanUpRuntimeEvents() {
    // Remove all touch events added during 'this.#onDown' as well.
    document.removeEventListener('touchmove', this.#onMove)
    document.removeEventListener('touchend', this.#onUp)
    document.removeEventListener('touchcancel', this.#boundStopTracking)
    document.removeEventListener('mousemove', this.#onMove)
    document.removeEventListener('mouseup', this.#onUp)
  }

  /**
   * Handles up/end events
   */
  #onUp = function (this: Impetus, event: Event) {
    let normalizedEvent = this.#normalizeEvent(event)

    if (this.#pointerActive && normalizedEvent.id === this.#pointerId) {
      this.#stopTracking()
    }
  }.bind(this)

  /**
   * Handles move events
   */
  #onMove = function (this: Impetus, event: Event) {
    event.preventDefault()
    let normalizedEvent = this.#normalizeEvent(event)

    if (this.#pointerActive && normalizedEvent.id === this.#pointerId) {
      this.#pointerCurrentX = normalizedEvent.x
      this.#pointerCurrentY = normalizedEvent.y
      this.#addTrackingPoint(this.#pointerLastX, this.#pointerLastY)
      this.#requestTick()
    }
  }.bind(this)

  #isTouchEvent(event: Event): event is TouchEvent {
    return event.type === 'touchmove' || event.type === 'touchstart' || event.type === 'touchend'
  }

  #isMouseEvent(event: Event): event is MouseEvent {
    return event.type === 'mousemove' || event.type === 'mousedown' || event.type === 'mouseup'
  }

  /**
   * Creates a custom normalized event object from touch and mouse events
   */
  #normalizeEvent(event: Event): NormalizedEvent {
    if (this.#isTouchEvent(event)) {
      let touch = event.targetTouches[0] || event.changedTouches[0]
      return {
        x: touch.clientX,
        y: touch.clientY,
        id: touch.identifier,
      }
    } else if (this.#isMouseEvent(event)) {
      // mouse events
      return {
        x: event.clientX,
        y: event.clientY,
        id: undefined,
      }
    } else {
      throw new Error('Unknown event type')
    }
  }

  /**
   * Add all required runtime events
   */
  #addRuntimeEvents() {
    this.#cleanUpRuntimeEvents()

    // @see https://developers.google.com/web/updates/2017/01/scrolling-intervention
    document.addEventListener('touchmove', this.#onMove, {
      passive: false,
    })
    document.addEventListener('touchend', this.#onUp)
    document.addEventListener('touchcancel', this.#boundStopTracking)
    document.addEventListener('mousemove', this.#onMove, {
      passive: false,
    })
    document.addEventListener('mouseup', this.#onUp)
  }

  /**
   * Executes the update function
   */
  #callUpdateCallback() {
    this.#updateCallback.call(this.#element, this.#targetX, this.#targetY)
  }

  /**
   * Stops movement tracking, starts animation
   */
  #stopTracking() {
    this.#pointerActive = false
    this.#addTrackingPoint(this.#pointerLastX, this.#pointerLastY)
    this.#startDeceleratingAnimation()

    this.#cleanUpRuntimeEvents()
  }
  #boundStopTracking = this.#stopTracking.bind(this)

  /**
   * Records movement for the last 100ms
   */
  #addTrackingPoint(x: number, y: number) {
    let time = Date.now()
    while (this.#trackingPoints.length > 0) {
      if (time - this.#trackingPoints[0].time <= 100) {
        break
      }
      this.#trackingPoints.shift()
    }

    this.#trackingPoints.push({ x, y, time })
  }

  /**
   * prevents animating faster than current framerate
   */
  #requestTick() {
    if (!this.#ticking) {
      requestAnimationFrame(this.#updateAndRender)
    }
    this.#ticking = true
  }

  /**
   * Calculate new values, call update function
   */
  #updateAndRender = function (this: Impetus) {
    let pointerChangeX = this.#pointerCurrentX - this.#pointerLastX
    let pointerChangeY = this.#pointerCurrentY - this.#pointerLastY

    this.#targetX += pointerChangeX * this.#multiplier
    this.#targetY += pointerChangeY * this.#multiplier

    if (this.#bounce) {
      let diff = this.#checkBounds()
      if (diff.x !== 0) {
        this.#targetX -= pointerChangeX * this.#dragOutOfBoundsMultiplier(diff.x) * this.#multiplier
      }
      if (diff.y !== 0) {
        this.#targetY -= pointerChangeY * this.#dragOutOfBoundsMultiplier(diff.y) * this.#multiplier
      }
    } else {
      this.#checkBounds(true)
    }

    this.#callUpdateCallback()

    this.#pointerLastX = this.#pointerCurrentX
    this.#pointerLastY = this.#pointerCurrentY
    this.#ticking = false
  }.bind(this)

  /**
   * Initialize animation of values coming to a stop
   */
  #startDeceleratingAnimation() {
    let firstPoint = this.#trackingPoints[0]
    let lastPoint = this.#trackingPoints[this.#trackingPoints.length - 1]

    let xOffset = lastPoint.x - firstPoint.x
    let yOffset = lastPoint.y - firstPoint.y
    let timeOffset = lastPoint.time - firstPoint.time

    let D = timeOffset / 15 / this.#multiplier

    this.#deceleratingStartTime = Date.now()
    this.#deceleratingVelocityX = xOffset / D || 0 // prevent NaN
    this.#deceleratingVelocityY = yOffset / D || 0

    let diff = this.#checkBounds()

    if (
      Math.abs(this.#deceleratingVelocityX) > 1 ||
      Math.abs(this.#deceleratingVelocityY) > 1 ||
      !diff.inBounds
    ) {
      this.#decelerating = true
      requestAnimationFrame(this.#stepDeceleratingAnimation)
    }
  }

  /**
   * Determine position relative to bounds
   * @param restrict Whether to restrict target to bounds
   */
  #checkBounds(restrict: boolean = false) {
    let xDiff = 0
    let yDiff = 0

    if (this.#boundXmin !== undefined && this.#targetX < this.#boundXmin) {
      xDiff = this.#boundXmin - this.#targetX
    } else if (this.#boundXmax !== undefined && this.#targetX > this.#boundXmax) {
      xDiff = this.#boundXmax - this.#targetX
    }

    if (this.#boundYmin !== undefined && this.#targetY < this.#boundYmin) {
      yDiff = this.#boundYmin - this.#targetY
    } else if (this.#boundYmax !== undefined && this.#targetY > this.#boundYmax) {
      yDiff = this.#boundYmax - this.#targetY
    }

    if (restrict) {
      if (xDiff !== 0) {
        this.#targetX = xDiff > 0 ? this.#boundXmin! : this.#boundXmax!
      }
      if (yDiff !== 0) {
        this.#targetY = yDiff > 0 ? this.#boundYmin! : this.#boundYmax!
      }
    }

    return {
      x: xDiff,
      y: yDiff,
      inBounds: xDiff === 0 && yDiff === 0,
    }
  }

  /**
   * Animates values slowing down
   */
  #stepDeceleratingAnimation = function (this: Impetus) {
    if (!this.#decelerating) {
      return
    }

    this.#deceleratingVelocityX *= this.#friction
    this.#deceleratingVelocityY *= this.#friction

    this.#targetX += this.#deceleratingVelocityX
    this.#targetY += this.#deceleratingVelocityY

    let diff = this.#checkBounds()

    if (
      (Math.abs(this.#deceleratingVelocityX) > this.#stopVelocityThreshold ||
        Math.abs(this.#deceleratingVelocityY) > this.#stopVelocityThreshold ||
        !diff.inBounds) &&
      Date.now() - this.#deceleratingStartTime < this.#stopTimeThreshold
    ) {
      if (this.#bounce) {
        let reboundAdjust = 2.5

        if (diff.x !== 0) {
          if (diff.x * this.#deceleratingVelocityX <= 0) {
            this.#deceleratingVelocityX += diff.x * bounceDeceleration
          } else {
            let adjust = diff.x > 0 ? reboundAdjust : -reboundAdjust
            this.#deceleratingVelocityX = (diff.x + adjust) * bounceAcceleration
          }
        }
        if (diff.y !== 0) {
          if (diff.y * this.#deceleratingVelocityY <= 0) {
            this.#deceleratingVelocityY += diff.y * bounceDeceleration
          } else {
            let adjust = diff.y > 0 ? reboundAdjust : -reboundAdjust
            this.#deceleratingVelocityY = (diff.y + adjust) * bounceAcceleration
          }
        }
      } else {
        if (diff.x !== 0) {
          if (diff.x > 0) {
            this.#targetX = this.#boundXmin!
          } else {
            this.#targetX = this.#boundXmax!
          }
          this.#deceleratingVelocityX = 0
        }
        if (diff.y !== 0) {
          if (diff.y > 0) {
            this.#targetY = this.#boundYmin!
          } else {
            this.#targetY = this.#boundYmax!
          }
          this.#deceleratingVelocityY = 0
        }
      }

      this.#callUpdateCallback()

      requestAnimationFrame(this.#stepDeceleratingAnimation)
    } else {
      this.#decelerating = false
    }
  }.bind(this)

  /**
   * Returns a value from around 0.5 to 1, based on distance
   */
  #dragOutOfBoundsMultiplier(value: number) {
    return 0.000005 * Math.pow(value, 2) + 0.0001 * value + 0.55
  }

  /**
   * Initializes movement tracking
   */
  #onDown = function (this: Impetus, event: Event) {
    let normalizedEvent = this.#normalizeEvent(event)
    if (!this.#pointerActive && !this.#paused) {
      this.#pointerActive = true
      this.#decelerating = false
      this.#pointerId = normalizedEvent.id

      this.#pointerLastX = this.#pointerCurrentX = normalizedEvent.x
      this.#pointerLastY = this.#pointerCurrentY = normalizedEvent.y
      this.#trackingPoints = []
      this.#addTrackingPoint(this.#pointerLastX, this.#pointerLastY)

      this.#addRuntimeEvents()
    }
  }.bind(this)

  constructor({
    source: element = document,
    update: updateCallback = () => {},
    multiplier = 1,
    friction = 0.92,
    initialValues = [0, 0],
    boundX = undefined,
    boundY = undefined,
    bounce = true,
  }: Partial<ImpetusOptions>) {
    this.#stopVelocityThreshold = stopVelocityThresholdDefault * multiplier
    this.#stopTimeThreshold = stopTimeThresholdDefault

    if (!updateCallback) {
      throw new Error('IMPETUS: update function not defined.')
    }

    this.#updateCallback = updateCallback
    this.#targetX = initialValues[0]
    this.#targetY = initialValues[1]
    this.#callUpdateCallback()

    // Initialize bound values
    this.#boundXmin = boundX?.[0]
    this.#boundXmax = boundX?.[1]
    this.#boundYmin = boundY?.[0]
    this.#boundYmax = boundY?.[1]

    this.#element = element
    this.#bounce = bounce
    this.#multiplier = multiplier
    this.#friction = friction

    this.#element.addEventListener('touchstart', this.#onDown)
    this.#element.addEventListener('mousedown', this.#onDown)
  }

  /**
   * In edge cases where you may need to
   * reinstanciate Impetus on the same sourceEl
   * this will remove the previous event listeners
   */
  destroy() {
    this.#element.removeEventListener('touchstart', this.#onDown)
    this.#element.removeEventListener('mousedown', this.#onDown)

    this.#cleanUpRuntimeEvents()
  }

  /**
   * Disable movement processing
   * @public
   */
  pause() {
    this.#cleanUpRuntimeEvents()

    this.#pointerActive = false
    this.#paused = true
  }

  /**
   * Enable movement processing
   * @public
   */
  resume() {
    this.#paused = false
  }

  /**
   * Update the current x and y values
   * @public
   */
  setValues(x: number, y: number) {
    if (typeof x === 'number') {
      this.#targetX = x
    }
    if (typeof y === 'number') {
      this.#targetY = y
    }
  }

  /**
   * Update the multiplier value
   * @public
   */
  setMultiplier(val: number) {
    this.#multiplier = val
    this.#stopVelocityThreshold = stopVelocityThresholdDefault * this.#multiplier
  }

  /**
   * Update boundX value
   * @public
   */
  setBoundX(boundX: [number, number]) {
    this.#boundXmin = boundX[0]
    this.#boundXmax = boundX[1]
  }

  /**
   * Update boundY value
   * @public
   */
  setBoundY(boundY: [number, number]) {
    this.#boundYmin = boundY[0]
    this.#boundYmax = boundY[1]
  }
}
