<template>
  <div
    class="scrubber swiper-no-swiping"
    :class="{ scrubbing, inert }"
    :style="{ '--progress': fraction }"
    ref="scrubberRef"
    @pointerdown="start"
  >
    <div class="scrubber-progress">
      <div class="scrubber-bar">
        <div class="scrubber-bar-head" />
      </div>
    </div>
  </div>
</template>

<script setup>
let props = defineProps({
  max: { type: Number, default: 100 },
  modelValue: { type: Number, required: true },
  inert: Boolean,
})

let emit = defineEmits(['input', 'update:scrubbing', 'update:modelValue'])

let scrubberRef = ref()
let scrubbing = ref(false)
let pendingValue = ref(0)
useEventListener('pointerup', () => {
  scrubbing.value = false
})
useEventListener('touchend', () => {
  scrubbing.value = false
})

function start() {
  if (props.inert) return
  scrubbing.value = true
}

let value = useVModel(props, 'value', emit, {
  eventName: 'input',
})

let fraction = computed(() => props.modelValue / props.max)
let { elementX, elementWidth } = useMouseInElement(scrubberRef)

watch(scrubbing, isScrubbing => {
  emit('update:scrubbing', isScrubbing)
})

watch([scrubbing, elementX], () => {
  let progress = Math.max(0, Math.min(1, elementX.value / elementWidth.value))
  pendingValue.value = progress * props.max

  if (scrubbing.value) emit('update:modelValue', pendingValue.value)
})
</script>

<style lang="scss" scoped>
.scrubber {
  --scrubber-active-color: var(--primary-color);
  --scrubber-inactive-color: #fff;
  --scrubber-width: auto;
  --scrubber-height: 2px;
  --scrubber-head-size: 12px;
  --scrubber-padding: 5px 0;
  --scrubber-margin: 0 0;

  padding: var(--scrubber-padding);
  margin: var(--scrubber-margin);

  &:not(.inert) {
    cursor: pointer;
  }
}

.scrubber-progress {
  width: var(--scrubber-width);
  height: var(--scrubber-height);
  background-color: var(--scrubber-inactive-color);
}

.scrubber-bar {
  content: '';
  display: block;
  width: calc(var(--progress) * 100%);
  height: 100%;
  background-color: var(--scrubber-active-color);
}

.scrubber-bar-head {
  margin-left: 100%;
  border-radius: 50%;
  width: var(--scrubber-head-size);
  height: var(--scrubber-head-size);
  background: var(--scrubber-active-color);
  transform: translate(-50%, -50%) translateY(1px);
  transition: opacity 300ms;
  opacity: 0;

  @at-root .scrubber:not(.inert):hover &,
    .scrubber.scrubbing & {
    opacity: 1;
  }
}
</style>
