<template>
  <component
    :is="interactive ? 'button' : 'div'"
    class="scroll-button"
    :class="{ interactive }"
    @click="scrollDown"
  >
    <svg
      class="scroll-icon"
      aria-hidden="true"
      focusable="false"
      width="27"
      height="16"
      viewBox="0 0 27 16"
      xmlns="http://www.w3.org/2000/svg"
    >
      <polygon
        points="0 0 0 6 13.5 16 27 6 27 3.55271368e-15 13.5 10"
        fill="currentColor"
        fill-rule="evenodd"
      />
    </svg>
    <span class="scroll-label">
      <slot>Scrollen</slot>
    </span>
  </component>
</template>

<script setup>
import gsap, { Power2 } from 'gsap'
import ScrollTo from 'gsap/ScrollToPlugin'

if (!import.meta.env.SSR) {
  gsap.registerPlugin(ScrollTo)
}

let props = defineProps({
  interactive: Boolean,
})

function scrollDown() {
  if (!props.interactive) return

  // window.scrollTo({
  //   top: window.scrollY + window.innerHeight,
  //   behavior: 'smooth'
  // })

  gsap.to(window, {
    duration: 1.5,
    scrollTo: window.scrollY + window.innerHeight,
    ease: Power2.easeInOut,
  })
}
</script>

<style lang="scss" scoped>
.scroll-button {
  @include reset-form-ui;

  position: relative;
  color: var(--scroll-button-color, var(--primary-color));
  border-radius: 50%;
  width: var(--scroll-button-size, 80px);
  height: var(--scroll-button-size, 80px);
  background-color: var(--scroll-button-background, rgb(0 0 0 / 30%));
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: default;

  &.interactive {
    cursor: pointer;
  }

  .scroll-label {
    position: absolute;
    bottom: calc(100% + 1em);
    font-size: 18px;
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: -0.4px;
    writing-mode: vertical-lr;
  }

  svg {
    margin-bottom: -4px;
  }
}
</style>
