<script>
import gsap from 'gsap'

const MINIMUM_DURATION_FRACTION = 0.9

export default {
  props: {
    disabled: Boolean,
    duration: {
      type: Number,
      default: 0.3,
    },
  },
  methods: {
    cancel(el) {
      gsap.killTweensOf(el)
      gsap.set(el, {
        height: '',
      })
    },
    beforeEnter(el) {
      if (this.disabled) return

      gsap.set(el, {
        // opacity: 0,
      })
    },
    enter(el, done) {
      if (this.disabled) {
        done()
        return
      }

      // Clamp the animated content to avoid animation outside the viewport
      let rect = el.getBoundingClientRect()
      let availableHeight = window.innerHeight - rect.top

      // Element is outside viewport, don't animate
      if (availableHeight < 0 || rect.bottom < 0) {
        done()
        return
      }

      let effectiveHeight = Math.min(availableHeight, rect.height)
      let effectiveHeightFraction = effectiveHeight / window.innerHeight

      let duration = Math.max(
        MINIMUM_DURATION_FRACTION * this.duration,
        effectiveHeightFraction * this.duration,
      )
      let effectiveDuration = duration
      if (effectiveHeight < rect.height) {
        let hiddenHeight = rect.height - effectiveHeight
        let hiddenHeightFraction = hiddenHeight / effectiveHeight
        effectiveDuration *= 1 + hiddenHeightFraction
      }

      gsap.set(el, {
        overflow: 'hidden',
      })

      if (effectiveDuration > duration) {
        gsap.set(el, {
          // opacity: '',
          height: `${effectiveHeight}px`,
        })

        gsap.from(el, {
          duration: duration,
          // opacity: 0,
          height: 0,
          marginTop: 0,
          marginBottom: 0,
          paddingTop: 0,
          paddingBottom: 0,
          onComplete() {
            done()
          },
        })
      } else {
        gsap.set(el, {
          // opacity: '',
        })

        gsap.from(el, {
          duration,
          // opacity: 0,
          height: 0,
          marginTop: 0,
          marginBottom: 0,
          paddingTop: 0,
          paddingBottom: 0,
          onComplete() {
            done()
          },
        })
      }
    },
    leave(el, done) {
      if (this.disabled) {
        done()
        return
      }

      // Clamp the animated content to avoid animation outside the viewport
      let rect = el.getBoundingClientRect()
      let availableHeight = window.innerHeight - rect.top
      let effectiveHeight = Math.min(availableHeight, rect.height)
      let effectiveHeightFraction = effectiveHeight / window.innerHeight

      let duration = Math.max(
        MINIMUM_DURATION_FRACTION * this.duration,
        effectiveHeightFraction * this.duration,
      )

      gsap.set(el, {
        overflow: 'hidden',
      })

      if (effectiveHeight < rect.height) {
        gsap.set(el, {
          height: `${effectiveHeight}px`,
        })
      }

      gsap.to(el, {
        duration,
        height: 0,
        // opacity: 0,
        marginTop: 0,
        marginBottom: 0,
        paddingTop: 0,
        paddingBottom: 0,
        onComplete() {
          done()
        },
      })
    },
    reset(el) {
      if (this.disabled) return

      gsap.set(el, {
        height: '',
        // opacity: '',
        marginTop: '',
        marginBottom: '',
        paddingTop: '',
        paddingBottom: '',
        overflow: '',
      })
    },
  },
}
</script>

<template>
  <Transition
    @before-enter="beforeEnter"
    @enter="enter"
    @after-enter="reset"
    @enter-cancelled="cancel"
    @leave="leave"
    @after-leave="reset"
    @leave-cancelled="cancel"
  >
    <slot />
  </Transition>
</template>
