<script setup>
let props = defineProps({
  image: Array,
  link: Object,
  size: String,
})

let { data: imageHtml, pending } = await useWordpressData(
  `wp-remote/image/render/${props.image[0].id}?size=${props.size}`,
)
</script>

<template>
  <div v-if="!pending" class="wpce-image" v-html="imageHtml" v-enter />
</template>

<style lang="scss" scoped>
.wpce-image {
  &:not(:first-child) {
    margin-top: space(1.5);
  }

  &:not(:last-child) {
    margin-bottom: space(0.85);
  }

  &:is(.wpce-text + &) {
    margin-top: space(0.85);
  }

  & > :deep(img) {
    display: block;
  }
}
</style>
