<script setup>
let props = defineProps({
  button: Object,
})

let link = reactiveComputed(() => props.button.value.link)
let label = computed(() => link.label || props.button.label)
let isRefLink = computed(() => link.mode === 'ref')
let urlSuffix = computed(() =>
  link.enableUrlParam ? `${!/^[$#]/.test(link.hash) ? '?' : ''}${link.hash}` : '',
)

let { data: url, pending } = await (isRefLink.value
  ? useWordpressData(`wp-remote/link/url/${link.ref.id}`)
  : { pending: false, data: link.text })
</script>

<template>
  <ArrowLink v-if="!pending" :to="url + urlSuffix" :new-tab="button.value.link.target">{{
    label
  }}</ArrowLink>
</template>

<style lang="scss" scoped></style>
