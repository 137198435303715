<script setup>
let props = defineProps({
  size: Object,
})

let sizeVars = computed(() => {
  let breakpoints = ['xs', 'sm', 'md', 'lg', 'xl']

  return Object.fromEntries(
    breakpoints.map(breakpoint => [`--size-${breakpoint}`, props.size[breakpoint]]),
  )
})
</script>

<template>
  <div class="wpce-spacer" :style="sizeVars" />
</template>

<style lang="scss" scoped>
.wpce-spacer {
  --size-xs: 0;
  --size-sm: var(--size-xs, 0);
  --size-md: var(--size-sm, 0);
  --size-lg: var(--size-md, 0);
  --size-xl: var(--size-lg, 0);

  --size: var(--size-xs);
  height: space(#{var(--size)});

  @include mediaSM {
    --size: var(--size-sm);
  }

  @include mediaMD {
    --size: var(--size-md);
  }

  @include mediaLG {
    --size: var(--size-lg);
  }

  @include mediaXL {
    --size: var(--size-xl);
  }
}

// sm: 760,
//     md: 992,
//     lg: 1400,
//     xl: 1600
</style>
