<template>
  <div
    ref="container"
    class="fullscreen-video-container"
    :class="{ 'hide-content': isIdle && isPlaying }"
    :style="{
      '--horizontal-focus': horizontalFocus,
      '--vertical-focus': verticalFocus,
    }"
  >
    <div ref="videoPlaceholder" class="fullscreen-video-height-placeholder">
      <VideoPlayer
        class="fullscreen-video-player"
        :src="video"
        fit-video
        :poster="poster"
        :autoplay="autoplay"
        :show-controls="showControls"
        :idle-timer="idleTimer"
        v-model:idle="isIdle"
        v-model:playing="isPlaying"
      />
    </div>
    <div class="fullscreen-video-content">
      <slot />
    </div>
  </div>
</template>

<script setup>
let props = defineProps({
  video: String,
  poster: String,
  horizontalFocus: String,
  verticalFocus: String,
  showControls: {
    type: [Boolean, String],
    default: 'interaction',
    validator: v => ['interaction', 'hover', 'always', false].includes(v),
  },
  autoplay: {
    type: [Boolean, String],
    default: false,
    validator: v => ['intersect', true, false].includes(v),
  },
  idleTimer: {
    type: Number,
    default: 4000,
  },
})

let emit = defineEmits(['update:poster-loaded'])

if (props.poster?.length > 0) {
  let imagePreload = usePreload(toRef(props, 'poster'))
  watch(imagePreload.isComplete, isComplete => emit('update:poster-loaded', isComplete), {
    immediate: true,
  })
} else {
  emit('update:poster-loaded', true)
}

let isIdle = ref(false)
let isPlaying = ref(false)

let container = ref()
let videoPlaceholder = ref()
</script>

<style lang="scss" scoped>
.fullscreen-video-container {
  --video-controls-reserved-space: 60px;

  @media (min-height: 500px) {
    --video-controls-reserved-space: 90px;
  }

  display: grid;
  width: 100%;
  min-height: var(--100lvh);
  overflow: hidden;

  --bottom-offset: var(--video-controls-reserved-space);

  .fullscreen-video-height-placeholder {
    max-height: var(--100lvh);
    margin-top: 0;
    background-image: var(--background);
    background-position: var(--horizontal-focus, center) var(--vertical-focus, center);
    background-size: cover;
  }

  .fullscreen-video-player {
    width: 100%;
    height: 100%;
  }

  .fullscreen-video-content {
    display: flex;
    align-items: flex-end;
    z-index: 1;

    // Leave some room for the player controls
    max-height: calc(100% - var(--video-controls-reserved-space));
  }

  .fullscreen-video-height-placeholder,
  .fullscreen-video-content {
    grid-row: 1 / span 1;
    grid-column: 1 / span 1;
  }

  .fullscreen-video-content {
    pointer-events: none;
    transition: opacity 600ms;

    :deep(.scroll-button) {
      pointer-events: all;
    }
  }

  &.hide-content {
    cursor: none;

    .fullscreen-video-content {
      opacity: 0;
      transition-delay: 300ms;
    }
  }
}
</style>
