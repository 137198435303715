<template>
  <a
    :href="targetPage"
    class="slide-content"
    :class="{ 'playing-video': shouldPlay }"
    :style="{
      '--horizontal-focus': slideObject.imageHorizontalFocus,
      '--vertical-focus': slideObject.imageVerticalFocus,
    }"
    @mouseover="mouseenter"
    @mouseleave="mouseleave"
    @click="handleRouteChange"
    draggable="false"
  >
    <div class="background">
      <img
        :src="slideObject.image"
        :loading="slideObject.isFront() ? null : 'lazy'"
        @load="emit('loaded')"
      />
      <video
        v-if="slideObject.video"
        class="slide-video"
        preload="none"
        muted
        ref="videoRef"
        loop
        disablepictureinpicture
        disableremoteplayback
        :src="slideObject.video"
      />
    </div>
    <div class="content">
      <template v-if="slideObject.preview">
        <div class="preview">
          <div class="preview-content">
            <h2 class="preview-title">{{ slideObject.preview.title }}</h2>
            <p v-if="slideObject.preview.subtitle" class="preview-subtitle">
              {{ slideObject.preview.subtitle }}
            </p>
          </div>
        </div>
      </template>
    </div>
  </a>
</template>

<script setup>
let props = defineProps({
  slideObject: {
    type: Object,
    required: true,
  },
})
let emit = defineEmits(['expand', 'loaded'])

let targetPage = computed(() =>
  props.slideObject.slug.startsWith('-') ? '#' : `/work/${props.slideObject.slug}`,
)

let dragging = useDragRecognition()

function handleRouteChange(event) {
  event.preventDefault()
  if (!dragging.value) {
    emit('expand')
  }
}

let supportsHover = useMediaQuery('(hover: hover) and (pointer: fine)')

function mouseenter() {
  if (!supportsHover.value) return

  isHovering.value = true
}
function mouseleave() {
  isHovering.value = false
}

let isVideo = computed(() => Boolean(props.slideObject.video))
let videoRef = ref()
let isHovering = ref(false)

let controls = useMediaControls(videoRef)
let shouldPlay = computed(() => isVideo.value && isHovering.value)

watch(shouldPlay, value => {
  controls.playing.value = value
})
</script>

<style lang="scss" scoped>
// Styles moved up to WorkSlider.vue
</style>
