import { clamp } from 'lodash-es'

/**
 * Just a sensible default, screen-size-dependent friction value for Impetus.js
 */
export function useImpetusFriction({
  referenceSize = useWindowSize().width,
  minFriction = 5,
  maxFriction = 8,
  minFrictionScreen = 400,
  maxFrictionScreen = 1000,
} = {}) {
  let friction = computed(() => {
    let minFrictionScreenValue = unref(minFrictionScreen)
    let maxFrictionScreenValue = unref(maxFrictionScreen)

    let frictionRange = unref(maxFriction) - unref(minFriction)
    let screenRange = maxFrictionScreenValue - minFrictionScreenValue

    let screenSize = clamp(unref(referenceSize), minFrictionScreenValue, maxFrictionScreenValue)

    let fraction = (screenSize - minFrictionScreenValue) / screenRange
    let frictionValue = minFriction + fraction * frictionRange

    return 1 - frictionValue / 100
  })

  return friction
}
