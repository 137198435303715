<template>
  <Transition name="skew" appear>
    <component
      v-show="visible"
      :is="tag"
      ref="container"
      class="skew-transition"
      :style="{
        '--skew-transition': transition,
        '--skew-path-in-hidden': clipPathInHidden,
        '--skew-path-in-visible': clipPathInVisible,
        '--skew-path-out-hidden': clipPathOutHidden,
        '--skew-path-out-visible': clipPathOutVisible,
      }"
    >
      <slot />
    </component>
  </Transition>
</template>

<script setup>
let props = defineProps({
  visible: Boolean,
  transition: {
    type: String,
    default: '600ms',
  },
  tag: {
    default: 'div',
  },
  in: {
    type: String,
    default: 'ltr',
    validator: value => ['ltr', 'rtl'].includes(value),
  },
  out: {
    type: String,
    default: 'ltr',
    validator: value => ['ltr', 'rtl'].includes(value),
  },
})

let directionIn = computed(() => (props.in === 'ltr' ? 'left' : 'right'))
let directionOut = computed(() => (props.out === 'ltr' ? 'right' : 'left'))

let container = ref()
let { clipPath: clipPathInHidden } = useSkewPath(container, 0, {
  visibleSide: directionIn,
})
let { clipPath: clipPathInVisible } = useSkewPath(container, 1, {
  visibleSide: directionIn,
})
let { clipPath: clipPathOutHidden } = useSkewPath(container, 0, {
  visibleSide: directionOut,
})
let { clipPath: clipPathOutVisible } = useSkewPath(container, 1, {
  visibleSide: directionOut,
})
</script>

<style lang="scss" scoped>
.skew-enter-active,
.skew-leave-active {
  transition: clip-path var(--skew-transition);
}

.skew-leave-from {
  clip-path: var(--skew-path-out-visible);
}

.skew-leave-to {
  clip-path: var(--skew-path-out-hidden);
}

.skew-enter-from {
  clip-path: var(--skew-path-in-hidden);
}

.skew-enter-to {
  clip-path: var(--skew-path-in-visible);
}
</style>
