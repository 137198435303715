<template>
  <SwiperContainer ref="containerRef" class="media-swiper">
    <SwiperSlide v-for="(source, index) in media" :key="source">
      <div ref="mediaRef">
        <slot name="media" v-bind="{ source, index }">
          <slot v-if="isVideo(source)" name="video" v-bind="{ source, index }">
            <VideoPlayer :src="source" class="media-swiper-video" muted />
          </slot>
          <slot v-else name="image" v-bind="{ source, index }">
            <img :src="source" class="media-swiper-image" />
          </slot>
        </slot>
      </div>
    </SwiperSlide>
  </SwiperContainer>
</template>

<script setup>
import { delay, isVideo } from '@/assets/js/util'
import { SwiperSlide } from 'swiper/vue'

let props = defineProps({
  media: Array,
  childComponents: Array,
})

let mediaRef = ref()
let containerRef = ref()

function getActiveMediaComponent() {
  if (props.childComponents) {
    return props.childComponents.find(childComponent =>
      childComponent.vnode.el.matches('.swiper-slide-active *'),
    )
  } else {
    if (!mediaRef.value) return undefined

    return mediaRef.value.find(ref => {
      return childComponent.vnode.el.matches('.swiper-slide-active *')
    })
  }
}

function getInactiveMediaComponents() {
  if (props.childComponents) {
    return props.childComponents.filter(
      childComponent => !childComponent.vnode.el.matches('.swiper-slide-active *'),
    )
  } else {
    if (!mediaRef.value) return []

    return mediaRef.value.filter(ref => {
      return !unrefElement(ref).matches('.swiper-slide-active *')
    })
  }
}

onMounted(() => {
  /**
   * @type {import('swiper').Swiper}
   */
  let swiper = containerRef.value.swiper
  swiper.on('slideChange', async () => {
    await delay(0)

    let activeComponent = getActiveMediaComponent()
    let mediaControls = activeComponent.mediaControls ?? activeComponent.exposed.mediaControls
    let pausedManually = activeComponent.pausedManually ?? activeComponent.exposed.pausedManually
    let autoplay = (activeComponent.exposed?.autoplay ?? false) !== false

    if (mediaControls && autoplay && !pausedManually) {
      mediaControls.playing.value = true
    }

    for (let component of getInactiveMediaComponents()) {
      let mediaControls = component.mediaControls ?? component.exposed.mediaControls

      if (mediaControls) {
        mediaControls.playing.value = false
      }
    }
  })
})

onUnmounted(() => {
  containerRef.value?.exposed.swiper.destroy()
})
</script>

<style lang="scss" scoped>
.media-swiper {
  --max-height: min(800px, 80vh);

  .swiper-slide {
    align-self: center;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 0;
    width: 100%;
  }
}

:global(.media-swiper-image) {
  max-height: var(--max-height);
  display: block;
}

:global(.media-swiper-image img) {
  height: auto;
}

:global(.media-swiper-video) {
  max-height: var(--max-height);
}
</style>
