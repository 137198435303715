export function useHeadElement(element) {
  element = ref(element)

  watch(
    element,
    (elementValue, oldElementValue) => {
      if (!elementValue) {
        elementValue = []
      } else if (!Array.isArray(elementValue)) {
        elementValue = [elementValue]
      }

      if (!oldElementValue) {
        oldElementValue = []
      } else if (!Array.isArray(oldElementValue)) {
        oldElementValue = [oldElementValue]
      }

      for (let element of oldElementValue) {
        if (!elementValue.includes(element)) {
          element.remove()
        }
      }

      for (let element of elementValue) {
        if (!oldElementValue.includes(element) && !document.head.contains(element)) {
          document.head.appendChild(element)
        }
      }
    },
    { immediate: true },
  )

  tryOnScopeDispose(() => {
    if (element.value) {
      let elementValue = element.value
      if (!Array.isArray(elementValue)) {
        elementValue = [elementValue]
      }

      for (let element of elementValue) {
        element.remove()
      }
    }
  })
}
