<script setup>
let props = defineProps({
  children: Array,
})

let childComponents = useAsParent({ key: 'wpce-swiper' })
</script>

<template>
  <MediaSwiper :media="children" :child-components="childComponents">
    <template #media="{ source }">
      <WpceSwiperSlide :source="source" />
    </template>
  </MediaSwiper>
</template>

<style lang="scss" scoped></style>
