<template>
  <form
    ref="form"
    v-bind="proxiedAttributes"
    :class="{ validated, valid }"
    novalidate
    @submit="attemptSubmit"
    @input:validate="updateValidity"
  >
    <slot v-bind="{ validated, valid }" />
  </form>
</template>

<script>
export default {
  inheritAttrs: false,
  data: () => ({
    validated: false,
    valid: false,
  }),
  provide() {
    return {
      form: this,
    }
  },
  computed: {
    submitListener: vm => vm.$attrs.onSubmit,
    proxiedAttributes() {
      let { onSubmit, ...attrs } = this.$attrs
      return attrs
    },
  },
  mounted() {
    this.valid = this.$refs.form.checkValidity()
  },
  methods: {
    updateValidity(event) {
      this.valid = this.$refs.form.checkValidity()
    },
    validate({ silent = false } = {}) {
      let form = this.$refs.form
      if (form.checkValidity()) {
        return true
      } else {
        if (!silent) {
          // Request submit to mark invalid fields
          let submitButton = form.querySelector('[type=submit]')
          if (submitButton) {
            if (form.requestSubmit) {
              form.requestSubmit(submitButton)
            } else {
              // Safari does not support requestSubmit
              submitButton.click()
            }
          }

          // Scroll to and focus first invalid element
          let firstInvalidElement = form.querySelector(':invalid')

          firstInvalidElement.focus({
            preventScroll: true,
          })
        }

        return false
      }
    },
    async attemptSubmit(event) {
      this.validated = true

      // Wait one tick to allow things like submit-preventing <Alert>s to appear
      await this.$nextTick()

      let valid = this.$refs.form.checkValidity()

      this.$emit('attempt-submit', valid)

      if (valid) {
        if (this.submitListener) {
          this.submitListener(event)
        }
        if (!event.defaultPrevented) {
          this.$refs.form.submit()
        }
      } else {
        event.preventDefault()
        event.stopPropagation()

        this.validate()
      }
    },
  },
}
</script>

<style></style>
