export let useGlobalContentStore = defineStore('globalContent', () => {
  return {
    address: ['Arova Hallen', 'Winterthurerstrasse 713', '8247 Flurlingen'],
    phone: '+41 52 620 26 17',
    email: 'kontakt@moduleplus.ch',

    socialUrls: {
      linkedIn: 'https://ch.linkedin.com/company/moduleplus',
      instagram: 'https://www.instagram.com/moduleplus/',
      facebook: 'https://www.facebook.com/moduleplus.ch',
    },
  }
})
