<template>
  <component :is="component" v-bind="{ ...componentAttrs, ...$attrs }">
    <slot
      v-bind="{
        link: hasLink,
        external: hasExternalLink,
        anchor: hasAnchorLink,
      }"
    />
  </component>
</template>

<script>
// A "renderless" component that represents various kinds of buttons in this app:
// - regular, interactive buttons
// - internal or external links (by passing a 'link' attribute)
//
// Can be used as the root element of another component to avoid having to implement
// this functionality in multiple places.

export default {
  inheritAttrs: false,
  props: {
    link: {
      default: undefined,
    },
    disabled: Boolean,
  },
  computed: {
    hasLink: vm => typeof vm.link !== 'undefined',
    hasTextLink: vm => typeof vm.link === 'string',
    hasAnchorLink: vm => vm.hasTextLink && vm.link.startsWith('#'),
    hasExternalLink: vm => vm.hasTextLink && /^https?:\/\//.test(vm.link),
    hasNuxtLink: vm => vm.hasLink && !vm.hasAnchorLink && !vm.hasExternalLink,
    component() {
      if (this.disabled) {
        return 'button'
      } else if (this.hasNuxtLink) {
        return 'NuxtLink'
      } else if (this.hasLink) {
        return 'a'
      } else {
        return 'button'
      }
    },
    componentAttrs() {
      if (!this.disabled) {
        // Don't apply hrefs if link is "disabled"

        if (this.hasNuxtLink) {
          return { to: this.link }
        } else if (this.hasLink) {
          let attrs = { href: this.link }

          if ('target' in this.$attrs) {
            attrs.rel = 'noopener'
          }

          return attrs
        } else {
          return { type: 'button' }
        }
      } else {
        return {
          disabled: true,
        }
      }
    },
  },
}
</script>

<style lang="scss" scoped>
/* Some basic resets */

button {
  font: inherit;
  margin: 0;
  padding: 0;
  border: none;
  border-radius: 0;
  line-height: inherit;
  color: inherit;
  background: none;
  display: inline-block;

  &:not(:disabled) {
    cursor: pointer;
  }
}

a {
  color: inherit;
  text-decoration: none;
  display: inline-block;
}
</style>
