<script setup>
defineProps({})

onMounted(() => {
  watch(
    blogEntryRef,
    blogEntryRefValue => {
      if (!Array.isArray(blogEntryRefValue)) return

      blogEntryRefValue.forEach((blogEntryElement, index) => {
        // Use SetTimeout 0 here?
        useIntersectionObserver(
          blogEntryElement,
          ([{ isIntersecting }]) => {
            indexVisibility[index] = isIntersecting
          },
          {
            rootMargin: '0px 0px -35% 0px',
          },
        )
      })
    },
    { immediate: true },
  )
})

let { data: rawBlogEntries, pending } = await useWordpressData('wp-remote/posts')
let blogEntries = computed(
  () =>
    rawBlogEntries.value?.map(entry => ({
      ...entry,
      date: new Date(entry.date),
    })) ?? [],
)

let blogEntryRef = ref()
let indexVisibility = writableComputedReactive(() =>
  Object.fromEntries(blogEntries.value.map((_, index) => [index, false])),
)

let latestIntersectingEntry = computed(() => {
  if (!blogEntryRef.value) return undefined

  for (let i = blogEntryRef.value.length - 1; i >= 0; i--) {
    if (indexVisibility[i]) {
      return blogEntries.value[i]
    }
  }
})
</script>

<template>
  <div class="page-section" v-if="!pending">
    <ScrollButton class="blog-scroll-button" interactive>{{
      latestIntersectingEntry ? latestIntersectingEntry.date.getFullYear() : 'Timeline'
    }}</ScrollButton>

    <div class="container-wide">
      <div class="blog-grid">
        <template v-for="(entry, index) in blogEntries" :key="`entry-${entry.slug}`">
          <hr class="blog-entry-divider" v-if="index > 0" v-enter />
          <div
            class="blog-entry"
            :class="{ 'blog-entry-left': index % 2 === 0, 'blog-entry-right': index % 2 !== 0 }"
            ref="blogEntryRef"
          >
            <div class="blog-entry-image-container" v-enter>
              <img class="blog-entry-image" :src="entry.thumbnail" alt="" />
            </div>
            <div class="blog-entry-excerpt" v-enter>
              <WpceDocument :tree="entry.document" />
            </div>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.blog-scroll-button {
  --scroll-button-background: rgb(255 255 255 / 10%);
  position: fixed;
  right: 5vh;
  bottom: 5vh;
  visibility: hidden;
  z-index: 1;

  @include mediaSM {
    visibility: visible;
  }
}

.blog-grid {
  display: grid;
  grid-auto-flow: rows;
  grid-gap: min(5vw, 6rem);

  .blog-entry-divider {
    width: 100%;
    border: none;
    height: 2px;
    background-color: rgb(255 255 255 / 10%);
    margin: 0;
    padding: 0;
  }

  .blog-entry {
    --image-ratio: (700 / 470);
    --columns: 16;
    --image-width: 16;
    --hero-headline-size: 28px;

    display: grid;
    grid-template-rows: auto auto 1fr;
    grid-template-columns: repeat(var(--columns), 1fr);
    column-gap: 0;
    row-gap: 0;

    .blog-entry-facts {
      margin-bottom: calc(#{space(0.75)} + 1rem);
    }

    .blog-entry-link {
      display: block;
      width: fit-content;
      margin-top: calc(0.5rem + #{space(0.4)});
    }

    .blog-entry-facts,
    .blog-entry-title,
    .blog-entry-excerpt {
      grid-column: 1 / -1;
    }

    .blog-entry-image-container {
      grid-row: 1 / span 1;
      grid-column-start: 1;
      grid-column-end: span var(--image-width);
      position: relative;
      padding-top: calc(100% / var(--image-ratio));
      align-self: flex-start;
      margin-bottom: 1.5rem;
    }

    &.blog-entry-left .blog-entry-image-container {
      grid-column-start: calc(0 - var(--image-width) - 1);
      grid-column-end: span var(--image-width);
    }

    @include mediaMD {
      .blog-entry-facts,
      .blog-entry-excerpt {
        grid-column-start: calc(var(--image-width) + 2);
        grid-column-end: -1;
      }

      &.blog-entry-left {
        .blog-entry-facts,
        .blog-entry-excerpt {
          grid-column-start: 1;
          grid-column-end: span calc(var(--columns) - var(--image-width) - 1);
        }
      }
    }

    .blog-entry-image {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .blog-entry-facts {
      font-weight: 600;
    }

    .blog-entry-excerpt {
      margin: 0;
      margin-top: 1rem;
    }

    @include mediaSM {
      --hero-headline-size: 40px;
    }

    @include mediaMD {
      --image-ratio: (100 / 120);
      --image-width: 6;
      --hero-headline-size: 48px;

      // .blog-entry-excerpt {
      //   --hero-headline-size 20px;
      // }

      .blog-entry-image-container {
        grid-row: 1 / span 3;
      }

      .blog-entry-facts {
        grid-row: 1 / span 1;
      }

      .blog-entry-title {
        grid-row: 2 / span 1;
        font-size: 36px;
        line-height: 1;
      }

      .blog-entry-excerpt {
        grid-row: 3 / span 1;
      }
    }

    // @include mediaMD {
    //   --image-ratio: (700 / 470);
    //   --image-width: 8;

    //   .blog-entry-title {
    //     font-size: 48px;
    //   }

    //   .blog-entry-excerpt {
    //     font-size: 20px;
    //   }
    // }

    @include mediaLG {
      --image-ratio: (700 / 470);
      --image-width: 9;
      --hero-headline-size: 72px;

      .blog-entry-excerpt {
        font-size: 22px;

        display: flex;
        flex-direction: column;
        gap: space(0.75);
      }
    }
  }

  :deep(.wpce-hero-headline.wpce-hero-headline) {
    margin-top: 0;
    margin-bottom: 0;
  }
}
</style>
