<template>
  <div
    ref="container"
    class="fullscreen-image-container"
    :class="{ proportional: hasAspectRatio && !parallax }"
    :style="{
      '--parallax': parallax || undefined,
      '--parallax-abs': parallax ? parallaxAbs : undefined,
      '--offset-top': parallaxIsNegative ? parallax : undefined,
      '--background': `url('${image}')`,
      '--horizontal-focus': horizontalFocus,
      '--vertical-focus': verticalFocus,
      '--aspect-ratio': aspectRatio,
    }"
  >
    <div ref="imagePlaceholder" class="fullscreen-image-height-placeholder" />
    <div class="fullscreen-image-content">
      <slot />
    </div>
  </div>
</template>

<script setup>
let props = defineProps({
  image: String,
  aspectRatio: Number,
  horizontalFocus: String,
  verticalFocus: String,
  parallax: String,
})

let emit = defineEmits(['update:loaded'])

let hasAspectRatio = computed(() => typeof props.aspectRatio === 'number' && props.aspectRatio > 0)

let imagePreload = usePreload(toRef(props, 'image'))
watch(imagePreload.isComplete, isComplete => emit('update:loaded', isComplete), { immediate: true })

let container = ref()
let imagePlaceholder = ref()

function parseCssValue(value) {
  if (typeof value === 'number') {
    return { number: value, unit: 'px' }
  }

  if (typeof value !== 'string') return

  let number = parseFloat(value)
  let unit = value.match(/[0-9]([a-z]+)\s*$/)?.[1] ?? 'px'

  return { number, unit }
}

function assembleCssValue(value) {
  return `${value.number}${value.unit}`
}

let parallaxCssValue = computed(() => parseCssValue(props.parallax))
let parallaxIsNegative = computed(() => (parallaxCssValue.value?.number ?? 0) < 0)

let parallaxAbs = computed(() =>
  assembleCssValue({
    number: Math.abs(parallaxCssValue.value.number),
    unit: parallaxCssValue.value.unit,
  }),
)

watch(
  () => props.parallax,
  () => {
    if (!props.parallax) return

    let timeline = useGsapTimeline().add(
      useGsapTo(imagePlaceholder, {
        y: assembleCssValue({
          number: -1 * parallaxCssValue.value.number,
          unit: parallaxCssValue.value.unit,
        }),
      }),
      0,
    )

    useGsapScrollTrigger(container, {
      animation: timeline.instance,
      scrub: true,
      start: 'top bottom',
      end: 'bottom top',
    })
  },
  { immediate: true },
)
</script>

<style lang="scss" scoped>
.fullscreen-image-container {
  display: grid;
  width: 100%;
  min-height: var(--100lvh);
  overflow: hidden;

  &:is(main > :first-child) {
    min-height: var(--100svh);

    :deep(.align-top) {
      margin-top: 40px;

      @include mediaXL {
        margin-top: 0;
      }
    }
  }

  .fullscreen-image-height-placeholder {
    height: max(calc(100vh + var(--parallax-abs, 0px)), calc(100% + var(--parallax-abs, 0px)));
    // margin-top: calc(var(--parallax, 0px) * var(--parallax-initial-modifier));
    margin-top: var(--offset-top, 0);
    background-image: var(--background);
    background-position: var(--horizontal-focus, center) var(--vertical-focus, center);
    background-size: cover;
  }

  &.proportional {
    min-height: 0;

    .fullscreen-image-height-placeholder {
      height: calc(var(--100vw) / var(--aspect-ratio));
    }
  }

  .fullscreen-image-content {
    display: flex;
    align-items: flex-end;
    z-index: 1;
  }

  .fullscreen-image-height-placeholder,
  .fullscreen-image-content {
    grid-row: 1 / span 1;
    grid-column: 1 / span 1;
  }
}
</style>
