<template>
  <div
    class="progress-bar"
    :class="{ 'progress-bar-overlay': overlay }"
    :style="{ '--progress-bar-value': progress }"
  />
</template>

<script setup>
import { clamp } from 'lodash-es'

let props = defineProps({
  value: Number,
  overlay: Boolean,
})
let progress = computed(() => clamp(props.value, 0, 1))
</script>

<style lang="scss" scoped>
.progress-bar {
  width: var(--progress-bar-width, 100px);
  height: var(--progress-bar-height, 6px);
  overflow: hidden;
  border-radius: var(--progress-bar-height, 6px);
  pointer-events: none;

  &::before,
  &::after {
    content: '';
    display: block;
    height: 100%;
    background-color: var(--progress-bar-color, #fff);
  }

  &::before {
    width: 100%;
    opacity: var(--progress-bar-opacity, 0.333);
  }

  &::after {
    margin-top: calc(-1 * var(--progress-bar-height, 6px));
    width: calc(var(--progress-bar-value) * 100%);
  }

  &.progress-bar-overlay {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 1;
    margin-left: calc(-0.5 * var(--progress-bar-width, 100px));
    margin-top: calc(-0.5 * var(--progress-bar-height, 100px));
  }
}
</style>
