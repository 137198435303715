<script setup>
let props = defineProps({
  style: String,
  marginTop: String,
  marginBottom: String,
  flags: Object,
  id: String,
  children: Array,
})

let screen = useScreen()
let columns = computed(() => (screen.greaterOrEqual('sm').value ? 2 : 1))

let spacer = {
  id: 'masonry-section-item',
  name: '',
  children: [
    {
      id: 'spacer',
      name: '',
      children: [],
      properties: {
        size: {
          xs: 0,
          sm: 1.8,
        },
      },
      guid: '4108100a-f99d-4e3f-87c7-525ea5c9eea6',
    },
  ],
  properties: {},
  guid: '1a927aa8-3455-47a2-952b-8e9474eba7b5',
}

let items = computed(() =>
  screen.greaterOrEqual('sm').value && props.flags?.offset
    ? [spacer, ...props.children]
    : props.children,
)
</script>

<template>
  <div
    class="page-section masonry-section"
    :class="[style, `margin-top-${marginTop}`, `margin-bottom-${marginBottom}`]"
    :id="id"
  >
    <Masonry
      :items="items"
      :columns="columns"
      item-key="guid"
      gap="calc(1.8 * var(--spacing-unit))"
    >
      <template #default="{ item }">
        <WpceDocument :tree="item" />
      </template>
    </Masonry>
  </div>
</template>

<style lang="scss" scoped></style>
