<template>
  <ActionTrigger v-bind="$attrs" class="button">
    <slot v-if="$slots.dropdown" name="dropdown" slot="dropdown" />
    <span class="icon icon-before" v-if="$slots.iconBefore">
      <slot name="iconBefore" />
    </span>
    <!-- prettier-ignore -->
    <svg v-else-if="withArrow" class="before-icon arrow" aria-hidden="true" focusable="false" width="52" height="52" viewBox="0 0 52 52" xmlns="http://www.w3.org/2000/svg"><path d="M26 0c14.36 0 26 11.64 26 26S40.36 52 26 52 0 40.36 0 26 11.64 0 26 0Zm-.687 17.5h-3.289l5.807 8.7L22 34.5h3.282l6.01-8.283-5.98-8.717Z" fill="currentColor"/></svg>

    <slot />
    <span class="icon icon-after" v-if="$slots.iconAfter">
      <slot name="iconAfter" />
    </span>
  </ActionTrigger>
</template>

<script>
import ActionTrigger from '@/components/Util/ActionTrigger'

export default {
  inheritAttrs: false,
  components: { ActionTrigger },
  props: {
    withArrow: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    triggerClick(event) {
      if (this.$slots.dropdown && !event.target.matches('.button .header, .button .header *')) {
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.button {
  background-color: transparent;
  border: none;
  color: inherit;
  font-weight: bold;
  font-size: inherit;
  padding: 0;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  cursor: pointer;
  transition: all ease 120ms;
  user-select: none;

  &:disabled {
    opacity: 0.6;
    cursor: default;
  }

  @include focus {
    outline: none;
    text-decoration: underline;
  }

  // .active needs to be avoided if the button hosts a dropdown
  /* &:not(.active):not(:disabled) {
    &:hover {
      background-color: $dull-blue-600;
    }
    &:active {
      background-color: $dull-blue-900;
    }
    @include focus {
      box-shadow: 0 0 0 3px $dull-blue-300;
    }
  } */

  .icon-before {
    margin-left: -0.2em;
    margin-right: 0.65em;
  }

  .icon-after {
    margin-left: 0.65em;
    margin-right: -0.2em;
  }

  .icon {
    flex-shrink: 0;

    :deep(> svg, > img) {
      display: block;
    }
  }

  .before-icon {
    flex-shrink: 0;
    margin-right: 0.85em;

    &.arrow {
      margin-top: -0.2em;
    }
  }

  .after-icon {
    flex-shrink: 0;
    margin-left: 0.85em;
  }
}
</style>
