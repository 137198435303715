<script>
export default {
  inheritAttrs: false,
}
</script>

<script setup>
let props = defineProps({
  text: String,
})

let chars = computed(() => [...props.text])
</script>

<template>
  {{ chars.slice(0, -1).join('')
  }}<span class="slash-break-preventer">{{ chars.at(-1) }}<Slash v-bind="$attrs" /></span>
</template>

<style lang="scss" scoped>
.slash-break-preventer {
  white-space: nowrap;
}
</style>
