<template>
  <div
    ref="sliderRef"
    class="slider"
    :class="{
      'is-ready': isReady && frontLoaded,
      'with-grab': grabEnabled,
      'is-grabbing': isGrabbing,
      'is-hovering': isHovering,
    }"
    :style="{
      '--slider-height': sliderHeight,
      '--slide-width': `${slideWidth}px`,
    }"
  >
    <div
      v-if="expanding"
      ref="expandingSlide"
      class="slide expanding-slide"
      :style="{
        '--slide-factor': expanding.index === hoveredIndex ? hoverScale : '1',
        '--offset-x': expanding.rect.left,
        '--horizontal-focus': expanding.slide.imageHorizontalFocus,
        '--vertical-focus': expanding.slide.imageVerticalFocus,
      }"
    >
      <div class="slide-content">
        <div class="background">
          <img :src="expanding.slide.image" draggable="false" />
        </div>
        <div class="content">
          <template v-if="expanding.slide.preview">
            <div class="preview">
              <div class="preview-content">
                <h2 class="preview-title">{{ expanding.slide.preview.title }}</h2>
                <p v-if="expanding.slide.preview.subtitle" class="preview-subtitle">
                  {{ expanding.slide.preview.subtitle }}
                </p>
              </div>
            </div>
          </template>
        </div>
      </div>
    </div>
    <div class="inner" :style="innerStyle" ref="innerRef" :class="{ 'is-scrolling': isScrolling }">
      <div class="background-content-wrap" :class="{ visible: isBackgroundContentVisible }">
        <div class="background-content">
          <slot />
        </div>
        <div class="background-sidebar" v-show="screen.lg.value">
          <ContactData />
        </div>
      </div>
      <div
        class="slide"
        v-for="(slide, i) in slides"
        :key="slide.slug"
        :style="slide.style"
        ref="slidesRef"
        :class="{
          initialized: slide.initialized,
          active: active === i,
          hovered: hoveredIndex === i,
          'front-slide': i <= slidesPerView + 1,
          'first-slide': i === 0,
          ...slide.classes,
        }"
        @no-click="setActiveSlide(i)"
        @mouseenter="setHoveredIndex(i)"
        @mouseleave="resetHoveredIndex"
      >
        <WorkSlideContent :slide-object="slide" @expand="expand(i)" @loaded="loadedSlides.add(i)" />
      </div>
    </div>
    <div class="controls">
      <button
        type="button"
        @click="next"
        class="control-button"
        :disabled="virtualIndex >= slides.length"
      >
        <!-- prettier-ignore -->
        <svg aria-hidden="true" focusable="false" width="16" height="27" viewBox="0 0 16 27" xmlns="http://www.w3.org/2000/svg"><polygon points="0 0 0 6 13.5 16 27 6 27 3.55271368e-15 13.5 10" fill="currentColor" transform="rotate(-90 13.5 13.5)" fill-rule="evenodd"/></svg>
        <span class="sr-only">Nächstes Projekt</span>
      </button>
    </div>
  </div>
</template>

<script setup>
import { toPercent } from '@/assets/js/util'
import { clamp, range } from 'lodash-es'
import gsap, { Power2 } from 'gsap'

const UNFOLD_DURATION_SECONDS = 0.65
const SCROLL_DURATION_SECONDS = 0.5

let props = defineProps({
  items: Array,
  disabled: Boolean,
  offset: {
    type: Number,
    default: 0,
  },
  offsetAnimationDuration: {
    type: Number,
    default: 0,
  },
})

let pageTransitionStore = usePageTransitionStore()
let designStore = useDesignStore()
let router = useRouter()

let screen = useScreen()

let { width: windowWidth, height: windowHeight } = useWindowSize()
let innerRef = ref()
let sliderRef = ref()
let slidesRef = ref([])

let hoverScale = 2

let expanding = ref()
let hoveredIndex = ref()

let enteredHovered = false
let supportsHover = useMediaQuery('(hover: hover) and (pointer: fine)')
let additionalScroll = computed(() =>
  screen.md.value ? 0 : designStore.derived.triangleHeightFraction * windowHeight.value * 0.5,
)

function setHoveredIndex(index) {
  if (expanding.value) return
  if (!supportsHover.value) return
  if (props.disabled) return

  enteredHovered = true
  hoveredIndex.value = index
}
function resetHoveredIndex() {
  if (expanding.value) return
  if (props.disabled) return

  enteredHovered = false
  useTimeoutFn(() => {
    if (!enteredHovered && !expanding.value) {
      hoveredIndex.value = undefined
    }
  }, 0)
}

let isHovering = computed(() => typeof hoveredIndex.value === 'number')

watch(hoveredIndex, (index, previousIndex) => {
  let slide = slides.value[index]
  let previousSlide = slides.value[previousIndex]

  if (slide) {
    gsap.killTweensOf(slide)
    gsap.to(slide, {
      factor: 2,
      duration: UNFOLD_DURATION_SECONDS,
      onUpdate: render,
    })
  }

  if (previousSlide) {
    gsap.killTweensOf(previousSlide)
    gsap.to(previousSlide, {
      factor: 1,
      duration: UNFOLD_DURATION_SECONDS,
      onUpdate: render,
    })
  }

  render()
})

let { height: sliderHeight } = useElementSize(sliderRef)

let slidesPerView = computed(() => {
  let slidesPerView = 1

  if (windowWidth.value > 340) slidesPerView = 2
  if (windowWidth.value > 750) slidesPerView = 3
  if (windowWidth.value > 1200) slidesPerView = 4
  if (windowWidth.value > 1680) slidesPerView = 5

  return slidesPerView
})

let loadedSlides = ref(new Set())
let frontLoaded = computed(() =>
  range(0, Math.min(slidesPerView.value, slides.value.length - 1)).every(index =>
    loadedSlides.value.has(index),
  ),
)

let isReady = ref(false)
let innerStyle = reactive({ minHeight: 0 })
let index = ref(0)
let virtualIndex = writableComputed(() => index.value)
let active = ref(null)
let scroll = ref(0)
let timeout = ref(null)
let isScrolling = ref(false)
let isGrabbing = ref(false)
let grabEnabled = ref(false)

let slideWidth = computed(() => windowWidth.value / slidesPerView.value)
let innerWidth = computed(() => slides.value.length * slideWidth.value)
provide('slideWidth', slideWidth)

function createSlideObjects(items) {
  return items.map((item, index) => ({
    slug: item.slug,
    image: item.preview.image,
    imageHorizontalFocus: toPercent(item.hero.focus[0]),
    imageVerticalFocus: toPercent(item.hero.focus[1]),
    video: item.preview.video,
    preview: item.preview,
    factor: 1,
    isFront: () => index < slidesPerView.value + 1,
    index,
    initialized: false,
    transform: {
      x: 0,
      y: 0,
    },
    zIndex: 0,
    style: {
      transform: null,
    },
    classes: [],
  }))
}

let slides = writableComputed(() => createSlideObjects(props.items))

function frame() {
  render()
}

watch(
  () => props.offset,
  offset => {
    if (props.offsetAnimationDuration > 0) {
      gsap.to(scroll, {
        value: offset,
        ease: Power2.easeOut,
        duration: props.offsetAnimationDuration,
      })
    } else {
      setScroll(offset)
    }
  },
)

// CALL AFTER slidesPerView CHANGES
function resize() {
  setScroll(props.offset)
  frame()
}

function snapScroll(scroll) {
  let gridSize = slideWidth.value
  let snapCandidate = gridSize * Math.round(scroll / gridSize)
  snapCandidate = clamp(0, snapCandidate, innerWidth.value + additionalScroll.value)
  return snapCandidate
}

function render(options = {}) {
  if (!innerRef.value) return

  let defaultOptions = {
    slideTransitions: !isGrabbing.value && !isWheeling.value,
    snap: false,
    bounceEnds: true,
  }
  options = { ...defaultOptions, ...options }
  let targetScroll = scroll.value

  updateIndexFromScrollPosition(targetScroll)

  // "snap" option
  if (options.snap) {
    targetScroll = snapScroll(scroll.value)
    invertedImpetusX.value = targetScroll
  }

  // "slideTransition" option
  let noTransitionClass = 'no-transition'

  innerRef.value.classList.toggle(noTransitionClass, !options.slideTransitions)
  renderSlides(targetScroll)
}

/**
 * Calculates the proper position (transforms)
 * for all the slides.
 *
 * @param targetScroll
 */
function renderSlides(targetScroll) {
  let hoverFactors = slides.value.map(slide => slide.factor)

  slides.value.forEach((slide, i, slidesArray) => {
    let rect = slidesRef.value[i].getBoundingClientRect()
    let localSlideWidth = slideWidth.value

    let skewOffset = 0
    let scroll = -1 * targetScroll

    // Sum up hover factors that impact the current slide's position
    let factorsUntil = hoverFactors.slice(0, i)
    let factorsAfter = hoverFactors.slice(i + 1)
    let summedFactorUntil = factorsUntil.reduce((sum, factor) => sum + (factor - 1) * 0.5, 0)
    let summedFactorAfter = factorsAfter.reduce((sum, factor) => sum + (factor - 1) * 0.5, 0)
    let hoverOffsetFactor = summedFactorUntil - summedFactorAfter - (hoverFactors[i] - 1) * 0.5

    slide.transform.x =
      i * localSlideWidth + skewOffset + scroll + hoverOffsetFactor * localSlideWidth - i
    slide.style.transform = `translate(${slide.transform.x}px, ${slide.transform.y})`

    slide.style['--slide-factor'] = String(slide.factor)

    window.requestAnimationFrame(() => {
      slide.initialized = true
    })
  })
}

function setHeight() {
  let tallestSlide = Math.max(
    ...slidesRef.value.map($slide => $slide.getBoundingClientRect().height),
  )
  innerStyle.minHeight = `${tallestSlide}px`
}

function prev() {
  isGrabbing.value = false
  let targetIndex = index.value > 0 ? index.value - 1 : 0
  let targetScroll = targetIndex * slideWidth.value
  setScroll(targetScroll, { smooth: true })
}

function next() {
  if (index.value === slides.value.length - 1) {
    setScroll(maxScroll.value, { smooth: true })
  } else {
    isGrabbing.value = false
    let targetIndex =
      index.value < slides.value.length - 1 ? index.value + 1 : slides.value.length - 1
    let targetScroll = targetIndex * slideWidth.value
    setScroll(targetScroll, { smooth: true })
  }
}

function setScroll(newScroll, { smooth = false } = {}) {
  if (smooth) {
    gsap.killTweensOf(scroll)
    gsap.to(scroll, {
      value: newScroll,
      duration: typeof smooth === 'number' ? smooth : SCROLL_DURATION_SECONDS,
    })
  } else {
    scroll.value = newScroll
  }
}

function setActiveSlide(i) {
  if (props.disabled) return
  active.value = i
}

let stopResizeListen
onMounted(() => {
  stopResizeListen = useWindowResizeStartStop(
    () => {
      isReady.value = false
      clearTimeout(timeout.value)
    },
    () => {
      resize()
      timeout.value = setTimeout(() => {
        isReady.value = true
      }, 300)
    },
  )
})
onUnmounted(() => {
  stopResizeListen()
})

onKeyStroke('ArrowLeft', () => {
  if (props.disabled) return
  prev()
})
onKeyStroke('ArrowRight', () => {
  if (props.disabled) return
  next()
})

let impetus = useImpetus(sliderRef, {
  boundX: computed(() => [-innerWidth.value - additionalScroll.value, 0]),
  callback(x) {
    isGrabbing.value = true
  },
})

let invertedImpetusX = computed({
  get: () => -impetus.x.value,
  set: value => {
    impetus.x.value = -value
  },
})

syncRef(scroll, invertedImpetusX)

let debouncedUpdateIndexFromScrollPosition = useDebounceFn(() => {
  isGrabbing.value = false
  updateIndexFromScrollPosition(scroll.value)
}, 200)

watch(invertedImpetusX, () => {
  render()
  debouncedUpdateIndexFromScrollPosition()
})

let maxScroll = computed(() => innerWidth.value + additionalScroll.value)

let { isWheeling } = useWheel(sliderRef, delta => {
  if (props.disabled) return

  if (delta === 0 || !isWheeling.value) return

  requestAnimationFrame(() => {
    const minScroll = 0
    let targetScroll = scroll.value + delta
    targetScroll = clamp(targetScroll, minScroll, maxScroll.value)
    setScroll(targetScroll)
    render({
      slideTransitions: false,
    })
  })
})

watch(isWheeling, value => {
  if (value) {
    isGrabbing.value = false
    impetus.pause()
  } else {
    impetus.resume()
  }
})

let isBackgroundContentVisible = computed(
  () => index.value >= slides.value.length - slidesPerView.value,
)

onMounted(() => {
  setTimeout(() => {
    resize()
  }, 0)

  isReady.value = true
})

function getIndexForScrollPosition(scrollX) {
  return Math.round(scrollX / slideWidth.value)
}

function updateIndexFromScrollPosition(scrollPosition) {
  index.value = clamp(getIndexForScrollPosition(scrollPosition), 0, slides.value.length - 1)
  virtualIndex.value = getIndexForScrollPosition(scrollPosition)
}

let expandingSlide = ref()
function expand(index) {
  let slide = slides.value[index]
  if (slide.slug.startsWith('-')) return

  let rect = slidesRef.value[index].getBoundingClientRect()

  useEventListener(
    expandingSlide,
    'animationend',
    async () => {
      pageTransitionStore.disableOnce()

      // To avoid flickering between route changes, add the hero image as a plain DOM overlay element
      let overlay = document.createElement('div')
      Object.assign(overlay.style, {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundImage: `url('${slide.image}')`,
        backgroundSize: 'cover',
        backgroundPosition: `${slide.imageHorizontalFocus ?? 'center'} ${
          slide.imageVerticalFocus ?? 'center'
        }`,
      })
      document.body.append(overlay)

      // Go to project route
      router.push(`/work/${slide.slug}`)

      // Wait for the route to be ready, then remove the overlay
      window.addEventListener(
        'moduleplus:project-loaded',
        () => {
          overlay.remove()
        },
        { once: true },
      )
    },
    { once: true },
  )

  expanding.value = {
    rect,
    slide,
    index,
  }
}
</script>

<style lang="scss" scoped>
html,
body {
  overflow: hidden;
  overscroll-behavior-x: none;
}

.slider {
  width: 100vw;
  height: var(--100dvh);
  contain: strict; // same as size layout paint --> https://developer.mozilla.org/en-US/docs/Web/CSS/CSS_Containment
  overscroll-behavior: none; // https://developer.mozilla.org/de/docs/Web/CSS/overscroll-behavior

  &:not(.is-ready) {
    background-image: url('@/assets/img/logo-full.svg');
    background-position: center;
    background-repeat: no-repeat;
    @media (max-width: 950px) {
      background-size: 50%;
    }
  }

  &.is-ready {
    .inner {
      opacity: 1;
    }
  }

  &.with-grab {
    .slide {
      cursor: grab;
    }

    &.is-grabbing {
      .slide {
        cursor: grabbing;
      }
    }
  }

  .inner {
    //border: 1px solid red;
    position: relative;
    overflow: hidden;
    max-width: 100%;
    height: 100%;
    contain: size paint;
    transition: opacity ease 300ms;
    opacity: 0;

    .background-content-wrap {
      display: flex;
      align-items: center;
      justify-content: space-between;
      box-sizing: border-box;
      height: var(--100dvh);
      opacity: 0;
      transition: opacity ease 300ms;
      user-select: none;
      padding-left: 1rem;

      @include mediaMD {
        padding-left: calc(0.75 * var(--triangle-height-fraction) * var(--100dvh));
      }

      &.visible {
        opacity: 1;
      }

      .background-content {
        max-width: 100%;
        padding: 25px;

        & > * {
          width: fit-content;
          margin-left: auto;
          margin-right: auto;
        }

        .background-logo {
          max-width: 75%;
          margin-left: 7%;
        }
      }

      .background-sidebar {
        align-self: flex-end;
        padding: 0 4rem 3rem 0;
        font-size: 18px;
      }
    }
  }

  .slide {
    will-change: transform, width;
    position: absolute;
    top: 0;
    width: calc(var(--slide-factor, 1) * var(--slide-width, 0));
    height: 100%;
    box-sizing: border-box;
    margin-left: calc(var(--triangle-height-fraction) * var(--100dvh) / 2);

    &.hovered {
      // --slide-factor: v-bind('hoverScale');
    }

    // Avoid overlapping previous skewed slide
    pointer-events: none;

    &.active {
      z-index: 100;

      .image-placeholder {
        width: 50vw;
        position: fixed;
      }
    }

    img {
      max-width: 100%;
      user-select: none;
    }
  }

  .controls {
    position: fixed;
    z-index: 100;
    bottom: 2rem;
    right: 2rem;
    user-select: none;
    display: flex;
    justify-content: center;
    align-items: center;

    .control-button {
      @include reset-form-ui;

      --default-scroll-button-size: 60px;
      position: relative;
      color: var(--scroll-button-color, var(--primary-color));
      border-radius: 50%;
      width: var(--scroll-button-size, var(--default-scroll-button-size));
      height: var(--scroll-button-size, var(--default-scroll-button-size));
      background-color: var(--scroll-button-background, rgb(0 0 0 / 30%));
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      transition: opacity ease 400ms;

      @include mediaSM {
        --default-scroll-button-size: 80px;
      }

      &:disabled {
        opacity: 0;
        pointer-events: none;
      }
    }

    & > * {
      margin: 15px;
    }
  }

  @keyframes expand-container {
    to {
      width: 100vw;
      transform: translateX(0);
    }
  }

  @keyframes expand-content {
    to {
      transform: skew(0deg);
    }
  }

  @keyframes expand-preview {
    to {
      transform: skew(calc(-1 * var(--skew-angle)));
      opacity: 0;
    }
  }

  @keyframes expand-background {
    to {
      width: 100%;
      transform: skew(0deg);
      left: 0;
    }
  }

  .slide.expanding-slide {
    --expand-duration: 650ms;

    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
    transform: translateX(calc(1px * var(--offset-x, 0)));
    margin-left: 0;

    animation: expand-container var(--expand-duration);
    animation-fill-mode: forwards;

    .background {
      animation: expand-background var(--expand-duration);
      animation-fill-mode: forwards;

      img {
        opacity: 1;
      }
    }

    :deep(.slide-content) {
      animation: expand-content var(--expand-duration);
      animation-fill-mode: forwards;

      .preview {
        animation: expand-preview var(--expand-duration);
        animation-fill-mode: forwards;
      }
    }
  }

  :deep(.slide-content) {
    display: block;
    // border: 2px solid black;
    border-top: none;
    border-bottom: none;
    width: 100%;
    height: var(--100dvh);
    position: relative;
    transform: skew(calc(-1 * var(--skew-angle)));
    overflow: hidden;
    background-size: cover;
    text-decoration: none;

    // Re-enable pointers after they were disabled on parent
    pointer-events: all;

    --video-opacity: 0;

    &.playing-video {
      --video-opacity: 1;
    }

    .preview {
      text-transform: uppercase;
      filter: drop-shadow(0 0 1em black);
      text-align: center;
      margin-left: -17.5vh;
      margin-bottom: 20vh;
      opacity: 0;
      transition: opacity ease 400ms;

      @include can-not-hover {
        opacity: 1;
      }
    }

    .preview-title {
      color: #fff;
      margin: 0;
      font-size: clamp(18px, 3vmax, 56px);
    }

    .preview-subtitle {
      color: var(--primary-color);
      margin: 0;
      font-size: clamp(15px, 2vmax, 36px);
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: var(--horizontal-focus, center) var(--vertical-focus, center);
      transition: opacity ease 400ms;
      pointer-events: none;

      @include can-hover {
        @at-root .slider.is-hovering :deep(.slide-content) img {
          opacity: 0.6;
        }
      }
    }

    @at-root .slider.is-hovering :deep(.slide-content):hover {
      img {
        opacity: 1;
      }

      .preview {
        opacity: 1;
      }
    }

    .slide-video {
      object-fit: cover;
    }

    video {
      opacity: var(--video-opacity);
      transition: opacity ease 500ms;
      transition-delay: 300ms;
      transform: translateY(-100%);
      height: 100%;
      width: 100%;
    }

    .background {
      background-color: #0f0f0f;
      background-position: var(--horizontal-focus, center) var(--vertical-focus, center);
      position: absolute;
      z-index: -1;
      top: 0;
      left: calc(-0.5px * var(--slider-height) * var(--triangle-height-fraction));
      transform: translateX(calc(-1 * var(--slide-width, 0px)))
        translateX(calc((var(--slide-factor) - 1) * 0.5 * var(--slide-width)))
        skew(var(--skew-angle));
      background-size: cover;
      width: calc(
        (var(--slide-width) + 1px * var(--slider-height) * var(--triangle-height-fraction)) * 2
      );
      height: 100%;
      line-height: 0;
    }

    .content {
      transform: skew(var(--skew-angle));
      height: 100%;
      display: flex;
      align-items: flex-end;
      justify-content: center;
    }

    .number {
      $size: 50px;
      font-size: 30px;
      font-family: sans-serif;
      background-color: black;
      color: #ffce31;
      width: $size;
      height: $size;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      user-select: none;
    }
  }
}
</style>

<style lang="scss">
body {
  /* overscroll-behavior-x: none; */
}
</style>
