export function useVelocity(source, { throttle = 50 } = {}) {
  let { history, clear } = useThrottledRefHistory(source, {
    throttle,
    capacity: 2,
    trailing: false,
  })

  let clearTimeoutId
  watch(history, () => {
    clearTimeout(clearTimeoutId)
    clearTimeoutId = setTimeout(() => {
      clear()
    }, unref(throttle) * 2)
  })
  onScopeDispose(() => {
    clearTimeout(clearTimeoutId)
  })

  let unitsPerSecond = computed(() => {
    if (history.value.length < 2) return 0

    let diff = Math.abs(history.value[1].snapshot - history.value[0].snapshot)

    return (diff * 1000) / unref(throttle)
  })

  return unitsPerSecond
}
